<template>
<div class='b-appointment-choose-address'>
    <template v-if='isCreated'>
        <div class='h-text-center h-pt-30'>
            <h1>
                {{ isOnSiteFlow ? $t('SELECT.ADDRESS.APPOINTMENT.TITLE') : $t('SELECT.ADDRESS.APPOINTMENT.CLIENT.TITLE') }}
            </h1>
        </div>
        <AutocompleteSelector
            style='max-width: 760px;'
            isAutoFocus
            @sendPlace='setAppointmentPlace'
            @changePostCode='value => post_code = value'
            @input='input'
            @update='value => country = value'>
        </AutocompleteSelector>
    </template>
    <FwSpinLoader
        v-else
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader h-width-100p h-height-100p'
        :isActive='!isCreated'
        className='h-p-20'>
    </FwSpinLoader>

    <portal to='appointment-bar-buttons'>
        <FwButton
            v-if='address && !address.post_code'
            size='little'
            :disabled='!post_code || !address'
            borderRadiusType='small-border'
            @click.native='goNext'>
            {{ $t('BUTTON.NEXT') }}
        </FwButton>
    </portal>
</div>
</template>

<script lang='ts'>
import { Component, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { AppointmentWebApi } from '@/api/appointment/AppointmentApi';

import {
    CategoryLocationSkillNameType,
    AppointmentCategoryType,
    AppointmentOnSiteDataType,
    AppointmentSearchType,
} from '@/types/Appointment';
import { ParsedAddressData, GoogleMapPlaceType } from '@/types/GoogleMap';
import { ClientType } from '@/types/User';
import { CompaniesAddressDataType } from '@/types/Companies';
import { Country } from '@/types/Account';

import { AutocompleteSelector } from '@/components/nodes/AutocompleteSelector';

import NoDataMixin from '@/mixins/no-data-mixin';
import AppointmentMixin from '@/mixins/appointment';
import PermissionsMixin from '@/mixins/permissions';

import { parseAddress, addPostCodeToAddress } from '@/helpers/address';

const AppointmentStore = namespace('AppointmentStore');
const CalendarStore = namespace('CalendarStore');

@Component({
    components: {
        AutocompleteSelector,
    },
})
export default class AppointmentChooseAddressPage extends Mixins(NoDataMixin, AppointmentMixin, PermissionsMixin) {
    @AppointmentStore.State('appointmentDataToUpdate') appointmentDataToUpdate!: AppointmentSearchType | null;
    @AppointmentStore.State('client') client!: ClientType | null;
    @AppointmentStore.State('appointmentCategory') appointmentCategory!: AppointmentCategoryType;
    @AppointmentStore.State('locationName') locationName!: CategoryLocationSkillNameType;
    @AppointmentStore.State('placeParsedData') placeParsedData!: ParsedAddressData;
    @AppointmentStore.State('opportunityId') opportunityId!: string;
    @AppointmentStore.Mutation('setAppointmentPlace') setAppointmentPlace!: (place: GoogleMapPlaceType) => void;
    @AppointmentStore.Mutation('setPlaceParsedData') setPlaceParsedData!: (address: ParsedAddressData | null) => void;
    @AppointmentStore.Mutation('setAppointmentOnSiteData') setAppointmentOnSiteData!: (appointment: AppointmentOnSiteDataType) => void;
    @CalendarStore.Mutation('setPostCode') setPostCode!: (post_code: string) => void;
    @CalendarStore.Mutation('setCountryIso') setCountryIso!: (country_iso: string) => void;

    isCreated: boolean = false;
    post_code: string = '';
    countryString: string = '';
    address: null | ParsedAddressData = null;
    country: Country | null = null;

    get isOnsitePhoneVideoFlow() {
        return this.isOnPhoneFlow || this.isOnVideoFlow || this.isOnSiteFlow || this.isOnSiteInBranchFlow;
    }

    input(address: ParsedAddressData) {
        this.address = address;
        this.post_code = address.post_code;
        this.countryString = address.country;
        if (this.post_code) {
            this.goNext();
        }
    }

    handleKeyUp(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            this.goNext();
        }
    }

    async goNext() {
        if (this.address) {
            this.address.calizyAddress = addPostCodeToAddress(this.address.calizyAddress, this.post_code, this.countryString);
            this.address.post_code = this.post_code;
            this.setPlaceParsedData(this.address);
            if (this.$route.meta && this.$route.meta.onSite) {
                try {
                    const additionalData = this.country && this.country.country_iso ? { country_iso: this.country.country_iso } : {};
                    const appointment = await AppointmentWebApi.onSiteAppointments({
                        account_id: this.user().account_id,
                        appointment_category_id: this.appointmentCategory.id,
                        location: this.placeParsedData.calizyAddress,
                        category: 'location_category_on_site',
                        ...(this.opportunityId && { opportunity_id: this.opportunityId }),
                        ...(this.client && this.client.id && { client_id: this.client.id }),
                        ...(this.appointmentDataToUpdate && { in_update_mode: true }),
                        ...(this.address.longitude && { longitude: this.address.longitude }),
                        ...(this.address.latitude && { latitude: this.address.latitude }),
                        ...additionalData,
                    });

                    this.setAppointmentOnSiteData({ ...appointment, ...additionalData });
                    if (this.country && this.country.country_iso) {
                        this.setCountryIso(this.country.country_iso);
                    }
                    this.$router.push({ path: '/appointment/calendar/on-site' });
                } catch ({ response }) {
                    this.sentNotif(response);
                }
            } else if (this.$route.meta && this.$route.meta.onSiteInBranch) {
                try {
                    this.$router.push({ path: '/appointment/choose-branch' });
                } catch ({ response }) {
                    this.sentNotif(response);
                }
            } else {
                this.$router.push({ path: '/appointment/address/branch' });
            }
        }
    }

    created() {
        document.addEventListener('keyup', this.handleKeyUp);

        if (!this.appointmentCategory || !this.locationName) {
            this.$_NoDataMixin_bookFlowNoData();
        } else if (this.client && this.client.address) {
            if (this.placeParsedData && !this.isOnsitePhoneVideoFlow) {
                this.$router.push({ path: '/appointment/partners-location' });
                return;
            }
            const address: CompaniesAddressDataType = parseAddress(this.client.address);
            const parsedAddress: ParsedAddressData = {
                post_code: address.zip_code,
                address: address.address,
                country: address.country,
                calizyAddress: this.client.address,
            };
            this.address = parsedAddress;
            this.post_code = parsedAddress.post_code;
            if (this.isOnsitePhoneVideoFlow) {
                this.isCreated = true;
                this.setPostCode('');
                this.setCountryIso('');
            } else {
                this.goNext();
            }
        } else {
            this.isCreated = true;
        }
    }

    beforeDestroy() {
        document.removeEventListener('keyup', this.handleKeyUp);
    }
}
</script>

<style lang='sass'>
.fw-select-address .multiselect .fw-select__caret
    opacity: 0
.fw-select-address
    .fw-select-white
        box-shadow: none!important

.b-appointment-choose-address
    display: flex
    flex-flow: column nowrap
    justify-content: center
    align-items: center
    .multiselect__content
        width: 600px !important
</style>
