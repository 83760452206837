<template>
<div :class='formClassWrapper'
     class='b-new-appointment b-appointment-left-bar_form h-flex h-flex-center h-flex-dir-column'>
    <h2 class='h-text-center h-font-20'>
        {{ $t('LABEL.SEARCH.CLIENT') }}
    </h2>
    <form style='width: 330px'
          class='h-mt-15'
          :class='formClass'
          @keyup.enter='submitForm'>
        <div class='h-flex h-flex-dir-column'>
            <FwFormInput
                class='h-flex-1-0'
                :fieldHasError='fieldHasError(BOOKING_PROSPECT_CUSTOMER_ID)'
                labelType='label-medium'
                :title='`${$t(`LABEL.BY.PROSPECT.CUSTOMER`)}`'>
                <FwInput
                    :value='customer_id'
                    type='text'
                    name='text'
                    :placeholder='$t(`LABEL.BY.PROSPECT.CUSTOMER`)'
                    inputStyle='white-bigger'
                    :disabled='isDisabledCustomerIdField'
                    :error='getFieldErrorText(BOOKING_PROSPECT_CUSTOMER_ID)'
                    @update:value='value => customer_id = value'
                    @input='clearServerErrorsBase(BOOKING_PROSPECT_CUSTOMER_ID)'
                    @blur='addCurrentInputToValidateArray(BOOKING_PROSPECT_CUSTOMER_ID)'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='h-flex-1-0 h-mt-15 qa-direct-appointment__client-email'
                :fieldHasError='fieldHasError(BOOKING_CLIENT_EMAIL)'
                labelType='label-medium'
                :title='`${$t(`LABEL.BY.CLIENT.EMAIL`)}`'>
                <FwInput
                    :value='client_email'
                    type='text'
                    name='text'
                    :placeholder='$t(`LABEL.BY.CLIENT.EMAIL`)'
                    inputStyle='white-bigger'
                    :disabled='isDisabledEmailField'
                    :error='getFieldErrorText(BOOKING_CLIENT_EMAIL)'
                    @update:value='value => client_email = value'
                    @input='clearServerErrorsBase(BOOKING_CLIENT_EMAIL)'
                    @blur='addCurrentInputToValidateArray(BOOKING_CLIENT_EMAIL)'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='h-flex-1 h-mt-15'
                :fieldHasError='fieldHasError(BOOKING_CLIENT_PHONE)'
                labelType='label-medium'
                :title='`${$t(`LABEL.BY.CLIENT.PHONE`)}`'>
                <FwInput
                    :value='client_phone'
                    type='tel'
                    name='text'
                    :placeholder='$t(`LABEL.BY.CLIENT.PHONE`)'
                    :disabled='isDisabledPhoneField'
                    inputStyle='white-bigger'
                    :error='getFieldErrorText(BOOKING_CLIENT_PHONE)'
                    :propsKeyDownMethod='propsKeyDownMethodOnlyNumbers'
                    @update:value='value => client_phone = value'
                    @input='clearServerErrorsBase(BOOKING_CLIENT_PHONE)'
                    @blur='addCurrentInputToValidateArray(BOOKING_CLIENT_PHONE)'>
                </FwInput>
            </FwFormInput>
        </div>
    </form>

    <div class='h-ph-50'>
        <div class='h-flex h-flex-center h-mt-30'>
            <FwButton
                class='h-mt-10 qa-direct-appointment__client-submit'
                borderRadiusType='small-border'
                :disabled='isDisable'
                @click.native.stop.prevent='submitForm'>
                {{ $t('BUTTON.NEXT') }}
            </FwButton>
        </div>
        <div class='h-flex h-flex-center'>
            <FwButton
                class='h-mt-20 h-flex h-flex-center'
                :style='{
                    textTransform: `uppercase`
                }'
                color='transparent'
                fontSize='14px'
                @click.native.stop.prevent='$emit(`skipStep`)'>
                {{ $t('BUTTON.SKIP.DIRECT') }}
                <FwIcon
                    class='h-mh-5'
                    icon='arrow-right'
                    size='12'
                    color='#203f6a'>
                </FwIcon>
            </FwButton>
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Emit, Mixins, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { BookingFormHead } from '@/components/forms/nodes/BookingFormHead';
import { CancelReasonPopup } from '@/components/popups/CancelReasonPopup';
import { FormTabsHeader } from '@/components/forms/tabs/nodes/FormTabsHeader';

import {
    AppointmentCategoryType,
    NewAppointmentDataType,
} from '@/types/Appointment';
import { BookNewAppointmentType } from '@/types/Book';

import AuthMixin from '@/mixins/auth';
import DateMixin from '@/mixins/dateMixin';
import ValidationMixin from '@/mixins/validation';
import CalendarMixin from '@/mixins/calendar';

import {
    BOOKING_PROSPECT_CUSTOMER_ID,
    BOOKING_CLIENT_EMAIL,
    BOOKING_CLIENT_PHONE,
} from '@/helpers/booking';

const AppointmentStore = namespace('AppointmentStore');

@Component({
    validators: {
        [BOOKING_PROSPECT_CUSTOMER_ID](value: string) {
            return this.baseInputValidator({ value, isRequired: false });
        },
        [BOOKING_CLIENT_PHONE](value: string) {
            return this.baseInputValidator({ value, isRequired: false });
        },
        [BOOKING_CLIENT_EMAIL](value: string) {
            return this.emailValidator({ value, notRequired: true });
        },
    },
    components: {
        BookingFormHead,
        CancelReasonPopup,
        FormTabsHeader,
    },
})
export default class ClientAppointmentForm extends Mixins(ValidationMixin, CalendarMixin, DateMixin, AuthMixin) {
    @AppointmentStore.Mutation('clearAppointmentState') clearAppointmentState!: () => void;
    @AppointmentStore.Mutation('setCurrentAppointmentKind') setCurrentAppointmentKind!: (appointmentKind: string) => void;

    @Prop({ type: String, default: null }) readonly formClassWrapper!: string;
    @Prop({ type: String, default: null }) readonly formClass!: string;
    @Prop({ type: Boolean, default: false }) readonly isRequestSending!: boolean;
    @Prop({ type: Object, default: null }) readonly eventData!: NewAppointmentDataType | null;
    @Prop({ type: Boolean, default: false }) readonly disabledForm!: boolean;
    @Prop({ type: Date, required: true }) readonly date!: Date;
    @Prop({ type: Boolean, default: false }) readonly canUpdateDisabledFields!: boolean;

    appointmentCategories: Array<AppointmentCategoryType> = [];

    [BOOKING_PROSPECT_CUSTOMER_ID]: string = '';
    [BOOKING_CLIENT_EMAIL]: string = '';
    [BOOKING_CLIENT_PHONE]: string = '';
    BOOKING_PROSPECT_CUSTOMER_ID: string = BOOKING_PROSPECT_CUSTOMER_ID;
    BOOKING_CLIENT_EMAIL: string = BOOKING_CLIENT_EMAIL;
    BOOKING_CLIENT_PHONE: string = BOOKING_CLIENT_PHONE;
    isLoading: boolean = false;
    serverErrors: serverErrors = {
        [BOOKING_PROSPECT_CUSTOMER_ID]: '',
        [BOOKING_CLIENT_EMAIL]: '',
        [BOOKING_CLIENT_PHONE]: '',
    };

    get isDisable(): boolean {
        return (this[BOOKING_CLIENT_EMAIL] && !this.isFormValid) || (!this.isDisabledCustomerIdField && !this.isDisabledEmailField && !this.isDisabledPhoneField);
    }

    get isDisabledCustomerIdField() {
        return !!(this[BOOKING_CLIENT_EMAIL] || this[BOOKING_CLIENT_PHONE]);
    }

    get isDisabledEmailField() {
        return !!(this[BOOKING_PROSPECT_CUSTOMER_ID] || this[BOOKING_CLIENT_PHONE]);
    }

    get isDisabledPhoneField() {
        return !!(this[BOOKING_PROSPECT_CUSTOMER_ID] || this[BOOKING_CLIENT_EMAIL]);
    }

    get dates(): Array<string> | null {
        if (!this.date) return null;
        const eventDate: Date = new Date(this.date);
        return [
            this.getCurrentDateAsMinutesAMPM(eventDate, false),
        ];
    }

    get dateFrom(): Date {
        if (!this.eventData) return this.date;
        return new Date(this.eventData.dt_start as string);
    }

    submitForm() {
        if (!this.isDisable) {
            this.onSubmit();
        }
    }

    isFormDisabled(key: string): boolean {
        return Boolean(this.disabledForm) && !this.canUpdateDisabledFields;
    }

    @Emit('onSubmit')
    onSubmit(): BookNewAppointmentType {
        return {
            [BOOKING_PROSPECT_CUSTOMER_ID]: this[BOOKING_PROSPECT_CUSTOMER_ID] || null,
            [BOOKING_CLIENT_EMAIL]: this[BOOKING_CLIENT_EMAIL] || null,
            [BOOKING_CLIENT_PHONE]: this[BOOKING_CLIENT_PHONE] || null,
        };
    }

    get isDayLessThenCurrent(): boolean {
        return this.eventData ? new Date(this.eventData.dt_start as string) < new Date() : false;
    }

    async created() {
        this.clearAppointmentState();
        this.setCurrentAppointmentKind('');
        if (this.eventData) {
            if (this.eventData.customer_id) {
                this.customer_id = this.eventData.customer_id || ``;
            }
            if (this.eventData.client_email) {
                this.client_email = this.eventData.client_email || ``;
            }
            if (this.eventData.client_phone) {
                this.client_phone = this.eventData.client_phone || ``;
            }
        }
        this.$emit('isLoading', false);
    }
}
</script>

<style lang='sass'>
.b-new-appointment
    input.fw-input.fw-input--white-bigger::placeholder
        width: 100%
.b-appointment-left-bar_form
    .fw-input--white, .fw-input--textarea
        box-shadow: none
        &::placeholder
            color: #909FB5
    .fw-input__error-text
        margin: 3px 0 15px
        font-size: 12px
        color: #FF004D
    .b-cancel-appointment__form
        .multiselect__tags
            min-height: 60px !important
            padding-left: 15px !important
            border-radius: 10px !important

    .fw-check-box__wrapper.with-text
        display: flex
        flex-direction: row
        width: 100%
        justify-content: flex-start
        margin-top: 10px
        .fw-check-box__label
            margin-left: 10px
            font-weight: 400

    .fw-select-white
        box-shadow: none !important

    .multiselect__input
        opacity: 1

    input.multiselect__input
        padding: 0 !important

    &__head
        height: 35px

.b-base-form-margin
    margin-bottom: 35px
    @include media('<tablet')
        margin-bottom: 10px

.h-align-items-end
    align-items: end

.b-booking-popup__wrapper
    .fw-popup
        overflow: auto
        display: block
</style>
