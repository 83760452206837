<template>
<div class='b-blue-background b-blue-background__opacity b-blue-background--fixed'>
    <FwPopup
        :padding='isMobile() ? "30px 20px" : "50px"'
        :centered='false'
        :isFixed='false'
        iconColor='#BEC7D4'
        max-width='100%'
        width='95%'
        :title='$t(`LABEL.GEOGRAFIC.ZONE`)'
        :style='{height: `90vh`, position: `relative`}'
        :allowBackgroundScrolling='false'
        :showClosePopup='true'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        heightValue='100%'
        @close='closePopup'>
        <template #body>
            <p v-if='!geoScopeInternational' class='b-geografic-main h-width-100p h-text-center h-fw-400 h-mt-0'>
                {{ $t(isCountryDepartments ? 'LABEL.GEOGRAPHIC.ZONE.DISTRICTS' : 'LABEL.GEOGRAPHIC.ZONE.CITIES') }}
            </p>
            <GeograficFormTabs
                v-if='geoScopeInternational'
                :countries='geoScopeCountries'
                :loading='loading'
                @updateCurrentTab='updateTab'>
            </GeograficFormTabs>
            <AddOrEditGeograficForm
                v-show='isCountryDepartments'
                :isRequestSendingAllow='isRequestSending'
                :loading='loading'
                :countryDepartments='countryDepartments'
                :beforeDepartmentsCloneData='beforeDepartmentsCloneData'
                :countryIso='countryIso'
                :departmentsByCountryIso='departmentsByCountryIso'
                @onSubmit='sendDataToServer'
                @searchLocalInputMethod='searchLocalInputMethod'
                @closePopup='$emit("closePopup")'>
            </AddOrEditGeograficForm>
            <AddOrEditGeograficCitiesForm
                v-show='!isCountryDepartments'
                :isRequestSendingAllow='isRequestSending'
                :loading='loading'
                :countryDepartments='countryDepartmentsCities'
                :beforeDepartmentsCloneData='beforeDepartmentsCloneData'
                :isStateChange='isCountryDepartments'
                :currentCity='currentCity'
                :countryIso='countryIso'
                @onSubmit='sendDataToServer'
                @goBack='goBack'
                @searchInputMethod='searchInputMethod'
                @closePopup='$emit("closePopup")'>
            </AddOrEditGeograficCitiesForm>
            <FwPagination
                v-if='!isCountryDepartments && totalPage > perPage'
                style='position: absolute; border-radius: 0 0 20px 20px'
                :totalPage='totalPage'
                :currentPage='currentPage'
                :perPage='perPage'
                :value='perPage'
                @getData='getCitiesList'>
            </FwPagination>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import { namespace } from 'vuex-class';
import { clone } from 'ramda';

import { Component, Watch, Mixins } from 'vue-property-decorator';

import { OnboardingApi } from '@/api/onboarding/OnboardingApi';

import { GeograficCitiesType, GeograficTypes } from '@/types/Appointment';

import PermissionsMixin from '@/mixins/permissions';

import { ADVISORS_DEPARTMENT_CITIES } from '@/helpers/workers';
import { sortGeograficPlaces } from '@/helpers/geografic';

import { AddOrEditGeograficForm } from '@/components/forms/add/AddOrEditGeograficForm';
import { AddOrEditGeograficCitiesForm } from '@/components/forms/add/AddOrEditGeograficCitiesForm';
import { GeograficFormTabs } from '@/components/forms/tabs/GeograficFormTabs';

const AppointmentStore = namespace('AppointmentStore');

@Component({
    components: {
        AddOrEditGeograficForm,
        AddOrEditGeograficCitiesForm,
        GeograficFormTabs,
    },
})

export default class AddEditGeograficPopup extends Mixins(PermissionsMixin) {
    @AppointmentStore.Mutation('setGeograficCitiesData') setGeograficCitiesData!: Function;
    @AppointmentStore.State('geograficCitiesData') geograficCitiesData!: Array<GeograficCitiesType>;
    loading: boolean = true;
    currentPage: number = 1;
    totalPage: number | null = 0;
    perPage: number = 100;
    timeout: number | NodeJS.Timeout = 0;
    searchText: string = '';
    isRequestSending: boolean = false;
    isCountryDepartments: boolean = true;
    currentCity: GeograficTypes = ADVISORS_DEPARTMENT_CITIES;
    countryIso: string | null = null;
    countryDepartments: Array<GeograficTypes> = [];
    cloneCountryDepartments: Array<GeograficTypes> | null = null;
    countryDepartmentsCities: Array<GeograficTypes> | null = null;
    beforeDepartmentsCloneData: Array<GeograficCitiesType> = [];
    departmentsByCountryIso: any = {};

    goBack() {
        this.currentCity = ADVISORS_DEPARTMENT_CITIES;
        this.isCountryDepartments = true;
    }

    closePopup() {
        this.setGeograficCitiesData(this.beforeDepartmentsCloneData);
        this.$emit('closePopup');
    }

    searchInputMethod(text: string) {
        this.searchText = text;
    }

    async searchLocalInputMethod(text: string) {
        this.loading = true;
        await clearTimeout(this.timeout);
        this.timeout = window.setTimeout(() => {
            this.loading = true;
            if (text && this.cloneCountryDepartments) {
                this.countryDepartments = this.cloneCountryDepartments.filter(item => {
                    return (`${item.post_code} - ${item.name}`)
                        .toUpperCase()
                        .includes(text.toUpperCase());
                });
            } else {
                this.countryDepartments = this.cloneCountryDepartments || [];
            }
            this.loading = false;
        }, 500);
    }

    search() {
        clearTimeout(this.timeout);
        this.timeout = window.setTimeout(() => {
            this.getGeograficZoneBySearch(this.currentPage, this.perPage, this.searchText);
        }, 1000);
    }

    getCitiesList(el: number, val: number) {
        this.currentPage = el;
        this.perPage = val;
        if (this.searchText.length > 1) {
            this.getGeograficZoneBySearch(el, val, this.searchText);
        } else if (this.searchText.length === 0) {
            this.sendDataToServer(this.currentCity);
        }
    }

    updateTab(countryIso: string) {
        this.isCountryDepartments = true;
        this.getGeograficZone(countryIso);
    }

    async sendDataToServer(payloadData: GeograficTypes) {
        this.isRequestSending = true;
        this.currentCity = payloadData;
        const payload: any = {
            current_page: this.currentPage,
            per_page: this.perPage,
        };
        try {
            const data = await OnboardingApi.getGeograficDepartments(payloadData, payload, this.countryIso as string);
            this.countryDepartmentsCities = sortGeograficPlaces(data.cities);
            this.isCountryDepartments = false;
            this.totalPage = data.meta.total_count;
        } catch ({ response }) {
            this.sentNotif(response);
        }
    }

    async getGeograficZone(country_iso: string | null) {
        this.countryIso = country_iso;
        this.loading = true;
        const data = await OnboardingApi.getGeograficCities('departments', this.countryIso);
        this.countryDepartments = sortGeograficPlaces(data.departments);
        if (this.geoScopeInternational) {
            this.departmentsByCountryIso[country_iso as string] = this.countryDepartments;
        }
        this.cloneCountryDepartments = clone(this.countryDepartments);
        this.loading = false;
    }

    async getGeograficZoneBySearch(el: number, val: number, searchText: string) {
        if (this.searchText.length > 1) {
            this.loading = true;
            this.currentPage = el;
            const payload: any = {
                current_page: el,
                per_page: val,
            };
            const data = await OnboardingApi.getGeograficDataBySearch('cities', payload, searchText, this.currentCity!.id);
            this.totalPage = data.meta.total_count;
            this.loading = false;
            this.countryDepartmentsCities = sortGeograficPlaces(data.cities);
            this.loading = false;
        }
    }

    created() {
        if (this.geoScopeInternational) {
            this.countryIso = 'FRA';
        }
        this.getGeograficZone(this.countryIso);
        this.beforeDepartmentsCloneData = clone(this.geograficCitiesData);
    }

    @Watch('searchText')
    countSearchInput() {
        this.currentPage = 1;
        if (this.searchText.length === 0) {
            this.sendDataToServer(this.currentCity);
        } else {
            this.search();
        }
    }
}
</script>
<style lang='sass'>
@include media('<=desktop')
    .fw-popup
        width: 100%!important
</style>
