<template>
<div class='b-setting-account-page'>
    <FwSpinLoader
        v-if='loading || isRequestSending'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading || isRequestSending'
        className='h-p-20'>
    </FwSpinLoader>
    <div
        v-if='!loading'
        :style='{ opacity: isRequestSending ? 0 : 1 }'>
        <div class='b-settings__title'>
            {{ $t('SETTINGS.MENU.ACCOUNT.DETAILS') }}
            <FwButton
                v-if='canEdit'
                borderRadiusType='small-border'
                :disabled='disabled'
                size='little'
                @click.native='updateAccountData'>
                {{ $t('BUTTON.SAVE') }}
            </FwButton>
        </div>
        <form @keydown.stop.prevent.enter='updateAccountData'>
            <div class='h-flex h-mt-20 b-setting-account-avatar'
                 :class='{ "b-setting-account-avatar--disabled": !canEdit }'>
                <div class='h-flex h-flex-1-0'>
                    <FwFormInput
                        class='h-flex-1-0 h-mr-20'
                        :fieldHasError='fieldHasError(COMPANY_INFORMATION_NAME)'
                        labelType='label-medium'
                        :title='`${$t(`LABEL.NAME`)}*`'>
                        <FwInput
                            :value='name'
                            type='text'
                            name='text'
                            :placeholder='$t(`LABEL.COMPANY.NAME`)'
                            inputStyle='white-bigger'
                            :disabled='!canEdit'
                            :error='getFieldErrorText(COMPANY_INFORMATION_NAME)'
                            @update:value='value => name = value'
                            @input='checkForValidation'
                            @blur='addCurrentInputToValidateArray(COMPANY_INFORMATION_NAME)'>
                        </FwInput>
                    </FwFormInput>
                    <FwFormInput
                        class='h-flex-1-0 h-ml-20'
                        :fieldHasError='fieldHasError(COMPANY_INFORMATION_ADDRESS)'
                        labelType='label-medium'
                        :title='`${$t(`COMPANIES.FORM.INPUT.ADDRESS.LABEL`)}*`'>
                        <FwInput
                            :value='address'
                            type='text'
                            name='text'
                            :placeholder='$t(`COMPANIES.FORM.INPUT.ADDRESS.LABEL`)'
                            inputStyle='white-bigger'
                            :disabled='!canEdit'
                            :error='getFieldErrorText(COMPANY_INFORMATION_ADDRESS)'
                            @update:value='value => address = value'
                            @input='checkForValidation'
                            @blur='addCurrentInputToValidateArray(COMPANY_INFORMATION_ADDRESS)'>
                        </FwInput>
                    </FwFormInput>
                </div>
            </div>
        </form>
        <template v-if='isGeoScopeInternationalWithCountries'>
            <div class='b-settings__title h-mt-40'>
                {{ $t('SETTINGS.MENU.ACCOUNT.GEOGRAPHIC.COVERAGE') }}
            </div>
            <div class='b-settings__title-block h-flex h-mt-30'>
                <FwButton
                    v-for='(tab, index) in geoScopeCountries'
                    :key='index'
                    color='blue'
                    class='h-mr-10'
                    size='little'>
                    {{ tab.name }}
                </FwButton>
            </div>
        </template>
        <FwFormInput
            v-if='subAccounts && subAccounts.length'
            class='h-mt-30'
            labelType='label-medium'
            :title='`${$t("SETTINGS.MENU.ACCOUNT.SUB.ACCOUNT.LIST.URL")}`'>
            <SubAccountsData :subAccounts='subAccounts'></SubAccountsData>
        </FwFormInput>
        <div class='b-settings__title h-mt-40'>
            {{ $t('SETTINGS.MENU.ACCOUNT.YOUR.SUBSCRIPTION') }}
        </div>
        <div class='b-setting-account-page__cards'>
            <div class='h-flex h-flex-dir-column h-flex-1-0' style='max-width: 400px'>
                <div class='b-setting-account-page__plan h-flex h-flex-space-between'>
                    <span class='b-setting-account-page__plan-title' :style='{ "color": planTextInfo[account.licence].color }'>
                        <img v-if='planTextInfo[account.licence]'
                             :src='requireImage(planTextInfo[account.licence].logo)'>
                        {{ account.licence | capitalizeAll }}
                    </span>
                    <a class='b-setting-account-page__plan-subtitle' href='https://calizy.com/en/pricing' target='_blank'>
                        {{ $t('SETTINGS.MENU.ACCOUNT.SEE.PLANS') }}
                    </a>
                </div>
                <div class='b-line-dashed'></div>
                <div class='b-setting-account-page__info h-flex h-flex-justify-start'>
                    <div>
                        <span class='b-setting-account-page__info-title'>
                            {{ $t('SETTINGS.MENU.ACCOUNT.EMAIL.THIS.MONTH') }}
                        </span>
                        <div class='h-flex h-align-items-center h-mt-10'>
                            <FwIcon
                                class='h-mr-5 h-flex h-flex-center'
                                icon='mail_fill_blue'
                                size='17'
                                color='#203f6a'>
                            </FwIcon>
                            {{ emailCount }}
                        </div>
                    </div>
                    <div>
                        <span class='b-setting-account-page__info-title h-flex h-ml-50'>
                            {{ $t('SETTINGS.MENU.ACCOUNT.SMS.THIS.MONTH') }}
                        </span>
                        <div class='h-flex h-align-items-center h-mt-10'>
                            <FwIcon
                                class='h-ml-50 h-mr-5 h-flex h-flex-center'
                                icon='sms_fill_blue'
                                size='17'
                                color='#203f6a'>
                            </FwIcon>
                            {{ smsCount }}
                        </div>
                    </div>
                </div>
                <div class='b-line-dashed'></div>
            </div>
            <div class='b-setting-account-page__text'>
                <h2 class='h-font-20 h-mt-0'>
                    {{ planTextInfo[account.licence].title }}
                </h2>
                <div
                    v-for='(plan, index) in planTextInfo[account.licence].body'
                    :key='index'
                    class='h-flex h-flex-justify-start h-mt-20'>
                    <span v-if='plan.icon'>
                        <FwIcon
                            :icon='plan.icon'
                            color='#203f6a'>
                        </FwIcon>
                    </span>
                    <div
                        class='text-left'
                        :class='{ "h-ml-10": plan.icon }'>
                        <h3
                            v-if='plan.title'
                            class='h-m-0 h-font-14 h-fw-500'>
                            {{ plan.title }}
                        </h3>
                        <p class='h-m-0 h-font-14'>
                            {{ plan.text }}
                        </p>
                    </div>
                </div>
                <FwButton
                    class='h-width-100p h-mt-30'
                    size='little'
                    borderRadiusType='small-border'
                    @click='mailTo()'>
                    {{ $t('BUTTON.NEXT') }}
                </FwButton>
            </div>
        </div>
    </div>

    <FinalePopup
        v-if='isFinalePopupShow'
        :popupWidth='`440px`'
        :headerText='$t("SETTINGS.POPUP.SUCCESS.TITLE")'
        :subMessage='$t("SETTINGS.POPUP.SUCCESS.SUB")'
        width='100%'
        @close='isFinalePopupShow = false'>
    </FinalePopup>
</div>
</template>

<script lang='ts'>
import { Component, Mixins } from 'vue-property-decorator';
import { namespace, State } from 'vuex-class';

import { AccountType, SubAccountType } from '@/types/Account';

import { SettingsWebApi } from '@/api/settings/SettingsApi';

import ValidationMixin from '@/mixins/validation';
import PermissionsMixin from '@/mixins/permissions';

import { FinalePopup } from '@/components/popups/FinalePopup';
import { CalendarTable } from '@/components/common/CalendarTable';
import { CalendarTableButtons } from '@/components/common/CalendarTableButtons';
import { SubAccountsData } from '@/components/common/SubAccountsData';

import { isEqual } from '@/helpers/base';
import { AppointmentAdvisorUrlsBuilderType } from '@/types/Appointment';

import {
    COMPANY_INFORMATION_NAME,
    COMPANY_INFORMATION_ADDRESS,
} from '@/helpers/company';

type SettingsAccountFormType = {
    name: string
    address: string | null
}

const CommonStore = namespace('CommonStore');
const GlobalStore = namespace('GlobalStore');

@Component({
    validators: {
        [COMPANY_INFORMATION_NAME](value: string) {
            return this.requiredValidator({ value });
        },
        [COMPANY_INFORMATION_ADDRESS](value: string) {
            return this.requiredValidator({ value });
        },
    },
    components: {
        FinalePopup,
        CalendarTable,
        CalendarTableButtons,
        SubAccountsData,
    },
})
export default class SettingsAccountPage extends Mixins(ValidationMixin, PermissionsMixin) {
    @State(state => state.GlobalStore.account) account!: AccountType | object;
    @CommonStore.Getter('subAccounts') subAccounts!: Array<SubAccountType>;
    @GlobalStore.Mutation('setAccount') setAccount!: (account: AccountType) => void;

    loading: boolean = false;
    isFinalePopupShow: boolean = false;
    isRequestSending: boolean = false;
    [COMPANY_INFORMATION_NAME]: string = '';
    [COMPANY_INFORMATION_ADDRESS]: string = '';
    COMPANY_INFORMATION_NAME: string = COMPANY_INFORMATION_NAME;
    COMPANY_INFORMATION_ADDRESS: string = COMPANY_INFORMATION_ADDRESS;
    bigIndexes: Array<number> = [];

    serverErrors: serverErrors = {
        [COMPANY_INFORMATION_NAME]: null,
        [COMPANY_INFORMATION_ADDRESS]: null,
    };
    requiredFields: Array<string> = ['name', 'address'];

    startFormData: SettingsAccountFormType | null = null;

    get isEqual(): boolean {
        return isEqual(
            this.startFormData,
            {
                name: this.name,
                address: this.address,
            },
        );
    }

    get disabled() {
        return !this.isFormValid || this.isEqual || this.isRequestSending;
    }

    get planTextInfo() {
        return {
            essential: {
                title: this.$t('SETTINGS.MENU.ACCOUNT.PLAN.ESSENTIAL.TITLE'),
                logo: 'essential_logo.svg',
                color: '#27DBBD',
                body: [
                    {
                        icon: 'chart-blue',
                        title: this.$t('SETTINGS.MENU.ACCOUNT.PLAN.ESSENTIAL.STATISTIC.TITLE'),
                        text: this.$t('SETTINGS.MENU.ACCOUNT.PLAN.ESSENTIAL.STATISTIC.TEXT'),
                    },
                    {
                        icon: 'lock-blue',
                        title: this.$t('SETTINGS.MENU.ACCOUNT.PLAN.ESSENTIAL.SSO.TITLE'),
                        text: this.$t('SETTINGS.MENU.ACCOUNT.PLAN.ESSENTIAL.SSO.TEXT'),
                    },
                    {
                        icon: 'distance',
                        title: this.$t('SETTINGS.MENU.ACCOUNT.PLAN.ESSENTIAL.DISTANCE.TITLE'),
                        text: this.$t('SETTINGS.MENU.ACCOUNT.PLAN.ESSENTIAL.DISTANCE.TEXT'),
                    },
                    {
                        icon: 'sync-blue',
                        title: this.$t('SETTINGS.MENU.ACCOUNT.PLAN.ESSENTIAL.CRM.TITLE'),
                        text: this.$t('SETTINGS.MENU.ACCOUNT.PLAN.ESSENTIAL.CRM.TEXT'),
                    },
                ],
            },
            advanced: {
                title: this.$t('SETTINGS.MENU.ACCOUNT.PLAN.ADVANCED.TITLE'),
                logo: 'advanced_logo.svg',
                color: '#24B6E7',
                body: [
                    {
                        icon: 'month-view',
                        title: this.$t('SETTINGS.MENU.ACCOUNT.PLAN.ADVANCED.CALENDAR.TITLE'),
                        text: this.$t('SETTINGS.MENU.ACCOUNT.PLAN.ADVANCED.CALENDAR.TEXT'),
                    },
                    {
                        icon: 'kpi-blue',
                        title: this.$t('SETTINGS.MENU.ACCOUNT.PLAN.ADVANCED.KPIS.TITLE'),
                        text: this.$t('SETTINGS.MENU.ACCOUNT.PLAN.ADVANCED.KPIS.TEXT'),
                    },
                    {
                        icon: 'console',
                        title: this.$t('SETTINGS.MENU.ACCOUNT.PLAN.ADVANCED.CUSTOM.FIELDS.TITLE'),
                        text: this.$t('SETTINGS.MENU.ACCOUNT.PLAN.ADVANCED.CUSTOM.FIELDS.TEXT'),
                    },
                    {
                        icon: 'code',
                        title: this.$t('SETTINGS.MENU.ACCOUNT.PLAN.ADVANCED.CUSTOM.INTEGRATION.TITLE'),
                        text: this.$t('SETTINGS.MENU.ACCOUNT.PLAN.ADVANCED.CUSTOM.INTEGRATION.TEXT'),
                    },
                ],
            },
            enterprise: {
                title: this.$t('SETTINGS.MENU.ACCOUNT.PLAN.ENTERPRISE.TITLE'),
                logo: 'enterprise_logo.svg',
                color: '#FF004D',
                body: [
                    {
                        text: this.$t('SETTINGS.MENU.ACCOUNT.PLAN.ENTERPRISE.TEXT1'),
                    },
                    {
                        text: this.$t('SETTINGS.MENU.ACCOUNT.PLAN.ENTERPRISE.TEXT2'),
                    },
                ],
            },
        };
    }

    randomColor() {
        const colors = ['primary', 'blue', 'cancel', 'green'];
        return colors[Math.floor(Math.random() * colors.length)];
    }

    mailTo() {
        window.location.href = 'mailto:hello@calizy.com;';
    }

    checkForValidation() {
        if (this[COMPANY_INFORMATION_NAME]) {
            this.addCurrentInputToValidateArray(this[COMPANY_INFORMATION_NAME]);
            this.clearServerErrorsBase(this[COMPANY_INFORMATION_NAME]);
        }

        if (this[COMPANY_INFORMATION_ADDRESS]) {
            this.addCurrentInputToValidateArray(this[COMPANY_INFORMATION_ADDRESS]);
            this.clearServerErrorsBase(this[COMPANY_INFORMATION_ADDRESS]);
        }
    }

    async updateAccountData() {
        if (this.disabled) return;
        this.isRequestSending = true;
        try {
            const { data } = await SettingsWebApi.changeCompanySettingsData(
                {
                    name: this[COMPANY_INFORMATION_NAME],
                    address: this[COMPANY_INFORMATION_ADDRESS],
                },
                this.user().account_id
            );
            this.updateFormValues(data.account);
            this.isFinalePopupShow = true;
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.isRequestSending = false;
        }
    }

    async fetchAccountData() {
        this.loading = true;
        const { data } = await SettingsWebApi.getCompanySettingsData(this.user().account_id);
        this.updateFormValues(data.account);
        this.loading = false;
    }

    async copyLink(item: AppointmentAdvisorUrlsBuilderType | any) {
        await navigator.clipboard.writeText(item.link);
        this.sentNotif(`SELFCARE.YOUR.BOOKING.LINK.COPY.TO.CLIPBOARD`, true);
    }

    updateFormValues(account: AccountType) {
        this.setAccount(account);
        this[COMPANY_INFORMATION_NAME] = account.name;
        if (account.address) {
            this[COMPANY_INFORMATION_ADDRESS] = account.address;
        }
        this.avatar = account.logo || '';
        this.startFormData = {
            name: this.name,
            address: this.address,
        };
    }

    created() {
        if (!this.account || !(`id` in this.account)) {
            this.fetchAccountData();
        } else {
            this.updateFormValues(this.account);
        }
    }
};
</script>

<style lang='sass'>
.b-setting-account-page
    &__cards
        display: flex
        justify-content: space-between

        @include media('<=phone')
            flex-direction: column

            .b-line-dashed
                width: 100% !important

            .b-setting-account-page__text
                width: 100%
                max-width: none

    .fw-input--white-bigger-shadow
        font-weight: 400 !important
    .b-line-dashed
        box-sizing: border-box
        height: 1px
        width: 400px
        border: 1px dashed #BEC7D4
    &__plan
        margin: 30px 0 25px
        &-title
            color: #27DBBD
            font-size: 24px
            font-weight: bold
            letter-spacing: 0
            line-height: 20px
        &-subtitle
            display: flex
            align-items: flex-end
            color: #27DBBD
            font-size: 12px
            font-weight: bold
            letter-spacing: 0
            line-height: 14px
            text-transform: uppercase
    &__text
        margin: 30px 0 25px
        padding: 30px
        max-width: 300px
        border-radius: 10px
        background-color: #FFFFFF
        box-shadow: 0 30px 11px -17px rgba(33,63,107,0.05)
    &__info
        margin: 25px 0
        &-title
            display: flex
            align-items: center
            justify-content: center
            color: #BEC7D4
            font-size: 12px
            font-weight: bold
            letter-spacing: 0
            line-height: 14px
    .b-settings__title-block
        border-radius: 10px
        width: 100%
        padding: 20px
        background: #fff

    .b-settings__title-block
        border-radius: 10px
        width: 100%
        padding: 20px
        background: #fff

.b-setting-account-avatar
    img
        margin: 0 !important
        max-height: 170px

    &--disabled
        .fw-file-img
            opacity: 0.5

        .fw-file-upload__icons_wrapper
            display: none
</style>
