<template>
<div class='h-width-100p b-form-tabs-main'>
    <div class='b-form-tabs h-mb-50'>
        <div
            v-for='(tab, index) in tabs'
            ref='activeTabRef'
            :key='tab.name'
            :class='{
                "b-form-tabs__active-tab": index === currIndex,
                "b-pointer-none": loading,
            }'
            class='b-form-tabs__tab h-fw-500 h-text-center'
            @click='selectTab(tab, index)'>
            {{ $t(tab.name) }}
        </div>
        <div class='h-pos-abs b-form-tabs__line-active' :style='{ left: tabLeft + "px", width: tabWidth + "px" }'>
            <div class='b-form-tabs__line-active-box'></div>
        </div>
        <div class='b-form-tabs__line'></div>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Vue } from 'vue-property-decorator';

import { TabsType } from '@/types/Tabs';

@Component({
    components: {
    },
})
export default class TabsDefault extends Vue {
    @Prop({ type: Array, required: true }) readonly tabs!: Array<TabsType>;
    @Prop({ type: Boolean, default: false }) readonly loading!: boolean;
    @Prop({ type: String, default: 'personalized' }) readonly kind!: string;

    tabWidth: number = 100;
    tabLeft: number = 100;
    activeTabIndex: number = 0;

    $refs!: {
        activeTabRef: Array<HTMLElement>
    };

    get currIndex() {
        return this.tabs.findIndex(tab => tab.id === this.kind);
    }

    selectTab(selectedTab: TabsType, index: number) {
        this.$emit('updateCurrentTab', selectedTab.id);
        this.activeTabIndex = this.currIndex;
        this.updateTabWidth();
    }

    mounted() {
        this.updateTabWidth();
    }

    updateTabWidth() {
        this.$nextTick(() => {
            if (this.$refs && this.$refs.activeTabRef) {
                this.tabWidth = this.$refs.activeTabRef[this.currIndex].offsetWidth;
                this.tabLeft = this.$refs.activeTabRef[this.currIndex].offsetLeft;
            }
        });
    }
}
</script>

<style lang='sass'>
.b-form-tabs
    display: flex
    justify-content: center
    align-items: center
    color: $dark-blue
    &__line
        position: absolute
        bottom: -14px
        width: 100%
        height: 1px
        background: $dark-blue
        &-active
            display: flex
            justify-content: center
            position: absolute
            top: 30px
            transition: left 0.5s
            &-box
                height: 2px
                width: 50px
                background:  $aqua-marine-light
    &__active-tab
        position: relative
        display: flex
        justify-content: center
        align-items: center
        flex-flow: column nowrap
        color: $aqua-marine-light
    &__tab
        cursor: pointer
        margin: 0 30px
.b-form-tabs-main
    .component-fade-enter-active, .component-fade-leave-active
        transition: opacity 0.2s

    .component-fade-enter, .component-fade-leave-to
        opacity: 0
</style>
