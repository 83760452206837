<template>
<div class='b-add-service-point-form'>
    <form @keydown.stop.prevent.enter='onSubmit'>
        <div class='b-add-service-point-form__line'>
            <FwFormInput
                class='h-flex-1-0 h-ph-10'
                :fieldHasError='fieldHasError("name")'
                labelType='label-medium'
                :title='`${$t(`LABEL.NAME`)}*`'>
                <FwInput
                    class='qa-service-point-name'
                    :value='name'
                    type='text'
                    name='text'
                    :placeholder='$t(`LABEL.NAME`)'
                    inputStyle='white-bigger'
                    :error='getFieldErrorText("name")'
                    @update:value='value => name = value'
                    @input='clearServerErrorsBase("name")'
                    @blur='addCurrentInputToValidateArray("name")'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='h-flex-1-0 h-ph-10'
                :fieldHasError='fieldHasError("external_id")'
                labelType='label-medium'
                :title='`${$t(`LABEL.SERVICE_POINT.ID`)}`'>
                <FwInput
                    class='qa-service-point-id'
                    :value='external_id'
                    type='text'
                    name='text'
                    :placeholder='$t(`LABEL.ID`)'
                    inputStyle='white-bigger'
                    :error='getFieldErrorText("external_id")'
                    @update:value='value => external_id = value'
                    @input='clearServerErrorsBase("external_id")'
                    @blur='addCurrentInputToValidateArray("external_id")'>
                </FwInput>
            </FwFormInput>
        </div>
        <div class='b-add-service-point-form__line'>
            <FwFormInput
                class='h-flex-1-0 h-ph-10 qa-service-point-address'
                :fieldHasError='fieldHasError(`name`)'
                :title='`${$t(`LABEL.ADDRESS`)}*`'
                labelType='label-medium'>
                <SelectAddress
                    class='b-select-address__outer qa-service-point-address'
                    :startAddress='address'
                    @changePostCode='value => post_code = value'
                    @input='input'>
                </SelectAddress>
            </FwFormInput>
        </div>

        <div class='b-add-service-point-form__line'>
            <FwFormInput
                class='h-flex-1-0 h-ph-10'
                :fieldHasError='fieldHasError("phone")'
                labelType='label-medium'
                :title='`${$t(`LABEL.PHONE.NUMBER`)}`'>
                <FwInput
                    :value='phone'
                    type='number'
                    name='text'
                    :placeholder='$t(`LABEL.PHONE.NUMBER`)'
                    inputStyle='white-bigger'
                    :error='getFieldErrorText("phone")'
                    :propsKeyDownMethod='propsKeyDownMethodOnlyNumbers'
                    @update:value='value => phone = value'
                    @input='clearServerErrorsBase("phone")'
                    @blur='addCurrentInputToValidateArray("phone")'>
                </FwInput>
            </FwFormInput>

            <FwFormInput
                class='h-flex-0-0-50p h-ph-10'
                :fieldHasError='fieldHasError("email")'
                labelType='label-medium'
                :title='`${$t(`LABEL.EMAIL`)}`'>
                <FwInput
                    :value='email'
                    type='text'
                    name='text'
                    :placeholder='$t(`LABEL.EMAIL`)'
                    inputStyle='white-bigger'
                    :disabled='disabledForm'
                    :error='getFieldErrorText("email")'
                    @update:value='value => email = value'
                    @input='clearServerErrorsBase("email")'
                    @blur='addCurrentInputToValidateArray("email")'>
                </FwInput>
            </FwFormInput>
        </div>

        <div class='b-add-service-point-form__line'>
            <FwFormInput
                class='h-flex-1-0 h-ph-10'
                :fieldHasError='fieldHasError("capacity")'
                labelType='label-medium'
                :title='`${$t(`LABEL.NUMBER_ADVISOR_SERVICE_POINT`)}`'>
                <FwInput
                    :value='capacity'
                    type='number'
                    name='text'
                    :placeholder='$t(`HUB.ADVISORS.NUMBER`)'
                    inputStyle='white-bigger'
                    :error='getFieldErrorText("capacity")'
                    :propsKeyDownMethod='propsKeyDownMethodOnlyNumbers'
                    @update:value='value => capacity = value'
                    @input='clearServerErrorsBase("capacity")'
                    @blur='addCurrentInputToValidateArray("capacity")'>
                </FwInput>
            </FwFormInput>
            <div class='h-flex-1-0 h-ph-10'></div>
        </div>
    </form>

    <div class='b-form-hint__wrapper'>
        <div class='h-mt-10 h-fw-500 h-font-13'>
            * {{ $t(`LABEL.REQUIRED.FIELD`) }}
        </div>
    </div>

    <div v-if='isEdit'
         class='h-flex h-flex-center h-flex-dir-column'>
        <FwButton
            class='h-mt-20 qa-service-point-form-submit'
            :disabled='isDisabled || isRequestSending'
            borderRadiusType='small-border'
            @click='onSubmit'>
            {{ $t('BUTTON.SAVE') }}
        </FwButton>
        <div v-if='canDelete'
             class='b-add-branch__form__delete qa-service-point-form-delete-button'
             @click='$emit("delete")'>
            {{ $t('SERVICES_POINT.DELETE.TXT') }}
        </div>
    </div>

    <div v-else class='h-flex h-flex-center'>
        <FwButton
            class='h-mt-30 qa-service-point-form-submit'
            :disabled='isDisabled || isRequestSending'
            borderRadiusType='small-border'
            @click='onSubmit'>
            {{ $t('BUTTON.SAVE') }}
        </FwButton>
    </div>
</div>
</template>

<script lang='ts'>
import { clone } from 'ramda';
import { Component, Mixins, Watch, Emit, Prop } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import ValidationMixin from '@/mixins/validation';
import { BranchSitesDataType } from '@/types/Site';
import { ParsedAddressData } from '@/types/GoogleMap';
import { addPostCodeToAddress } from '@/helpers/address';
import PermissionsMixin from '@/mixins/permissions';
import { SelectAddress } from '@/components/common/SelectAddress';
import { ServicePointFormDataPayloadType, ServicePointItemType } from '@/types/ServicePoint';
import { isEqual } from '@/helpers/base';

@Component({
    components: {
        SelectAddress,
    },
    validators: {
        name(value: string) {
            return this.baseInputValidator({ value });
        },
        address(value: string) {
            return this.baseInputValidator({ value });
        },
        email(value: string) {
            return this.emailValidator({ value, notRequired: true });
        },
    },
})
export default class AddOrEditBranchForm extends Mixins(ValidationMixin, PermissionsMixin) {
    @Prop({ type: Object, default: null }) readonly servicePoint!: ServicePointItemType;
    @Prop({ type: String, default: null }) readonly errorFromServer!: string;
    @Prop({ type: Boolean, default: false }) readonly isRequestSending!: boolean;

    name: string = '';
    address: string = '';
    phone: string = '';
    external_id: string = '';
    email: string = '';
    post_code: string = '';
    countryString: string = '';
    capacity: number | null = null;

    startFormData: ServicePointFormDataPayloadType | null = null;

    serverErrors: serverErrors = {
        name: null,
        address: null,
        external_id: null,
        email: null,
        capacity: null,
    };

    get isEdit(): boolean {
        return !!this.servicePoint;
    }

    get isDisabled(): boolean {
        if (this.isEdit) {
            return isEqual(this.startFormData, this.currentForm) || !this.isFormValid;
        }
        return !this.isFormValid;
    }

    input(parsedAddressData: ParsedAddressData): void {
        this.post_code = parsedAddressData.post_code;
        this.address = parsedAddressData.calizyAddress;
        this.countryString = parsedAddressData.country;
    }

    updateFormByServerValues(): void {
        if (this.servicePoint) {
            if (this.servicePoint.name) {
                this.name = this.servicePoint.name;
            }
            if (this.servicePoint.address) {
                this.address = this.servicePoint.address;
            }
            if (this.servicePoint.email) {
                this.email = this.servicePoint.email.trim();
            }
            if (this.servicePoint.phone) {
                this.phone = this.servicePoint.phone;
            }
            if (this.servicePoint.external_id) {
                this.external_id = this.servicePoint.external_id;
            }
            if (this.servicePoint.post_code) {
                this.post_code = this.servicePoint.post_code;
            }
            if (this.servicePoint.capacity) {
                this.capacity = this.servicePoint.capacity;
            }
        }
    }

    get requiredFields(): Array<string> {
        return this.servicePoint ? ['name'] : ['name', 'address'];
    }

    get currentForm(): ServicePointFormDataPayloadType {
        return {
            name: this.name,
            address: this.address || addPostCodeToAddress(this.address, this.post_code, this.countryString),
            phone: this.phone,
            external_id: this.external_id,
            email: this.email,
            post_code: this.post_code,
            capacity: this.capacity ? +this.capacity : 0,
        };
    }

    created(): void {
        if (this.isEdit) {
            this.updateFormByServerValues();
            this.startFormData = clone(this.currentForm);
        }
    }

    mounted(): void {
        if (this.isEdit) {
            this.addCurrentInputToValidateArray('name');
        }
    }

    @Watch('errorFromServer')
    checkEmailError(): void {
        if (this.errorFromServer.includes('Email')) {
            this.serverErrors = {
                // @ts-ignore-next-line
                email: this.$i18n.t('COMPANY.EMAIL.TAKEN'),
            };
        }
    }

    @Emit('onSubmit')
    onSubmit(): ServicePointFormDataPayloadType {
        return this.currentForm;
    }
}
</script>

<style lang='sass'>
.b-add-service-point-form
    @include media('>phone')
        padding: 40px

    &__line
        display: flex
        justify-content: space-between
        margin-bottom: 40px

        @include media('<=phone')
            flex-direction: column
            margin-bottom: 0

            .fw-form-input__wrapper
                margin-bottom: 10px

    .fw-input::placeholder
        width: 100% !important
        text-overflow: inherit

    .fw-select-address.fw-select-address__absolute .multiselect__content-wrapper
        top: 2px !important
</style>
