export default {
    'SETTINGS.ROLES.TITLE': 'Roles',
    'SETTINGS.ROLES.SEARCH.TEXT': 'Search for a role',

    'SETTINGS.MENU.HEADER': 'Settings menu',
    'SETTINGS.MENU.ACCOUNT': 'Account',
    'SETTINGS.MENU.ACCOUNT.DETAILS': 'Account details',
    'SETTINGS.MENU.ACCOUNT.GEOGRAPHIC.COVERAGE': 'Geographic coverage',
    'SETTINGS.MENU.ACCOUNT.YOUR.SUBSCRIPTION': 'Your subscription',
    'SETTINGS.MENU.ACCOUNT.SEE.PLANS': 'SEE PLANS',
    'SETTINGS.MENU.ACCOUNT.EMAIL.THIS.MONTH': 'EMAILS SENT THIS MONTH',
    'SETTINGS.MENU.ACCOUNT.SMS.THIS.MONTH': 'SMS SENT THIS MONTH',
    'SETTINGS.MENU.ACCOUNT.PLAN.ESSENTIAL.TITLE': 'Grow with Advanced Plan 🚀',
    'SETTINGS.MENU.ACCOUNT.PLAN.ESSENTIAL.STATISTIC.TITLE': 'Statistics dashboard',
    'SETTINGS.MENU.ACCOUNT.PLAN.ESSENTIAL.STATISTIC.TEXT': 'Manage the performance of your resources for better customer experiences.',
    'SETTINGS.MENU.ACCOUNT.PLAN.ESSENTIAL.SSO.TITLE': 'Save time with SSO',
    'SETTINGS.MENU.ACCOUNT.PLAN.ESSENTIAL.SSO.TEXT': 'Reduce time spent managing users & invites.',
    'SETTINGS.MENU.ACCOUNT.PLAN.ESSENTIAL.DISTANCE.TITLE': 'Schedule with travel time',
    'SETTINGS.MENU.ACCOUNT.PLAN.ESSENTIAL.DISTANCE.TEXT': 'Take into account travel distance for the itinerant',
    'SETTINGS.MENU.ACCOUNT.PLAN.ESSENTIAL.CRM.TITLE': 'CRM integration',
    'SETTINGS.MENU.ACCOUNT.PLAN.ESSENTIAL.CRM.TEXT': 'Simplify your processes by integrating Calizy with your existing tools.',
    'SETTINGS.MENU.ACCOUNT.PLAN.ADVANCED.TITLE': 'Grow with Enterprise Plan 🚀',
    'SETTINGS.MENU.ACCOUNT.PLAN.ADVANCED.CALENDAR.TITLE': 'Month calendar',
    'SETTINGS.MENU.ACCOUNT.PLAN.ADVANCED.CALENDAR.TEXT': 'See what your teams are during on an entire month, in one glance.',
    'SETTINGS.MENU.ACCOUNT.PLAN.ADVANCED.KPIS.TITLE': 'KPIs',
    'SETTINGS.MENU.ACCOUNT.PLAN.ADVANCED.KPIS.TEXT': 'Gain insight on your scheduling activity with KPI exports.',
    'SETTINGS.MENU.ACCOUNT.PLAN.ADVANCED.CUSTOM.FIELDS.TITLE': 'Custom fields',
    'SETTINGS.MENU.ACCOUNT.PLAN.ADVANCED.CUSTOM.FIELDS.TEXT': 'Personalize your users\' experience with custom parameters.',
    'SETTINGS.MENU.ACCOUNT.PLAN.ADVANCED.CUSTOM.INTEGRATION.TITLE': 'Custom integration',
    'SETTINGS.MENU.ACCOUNT.PLAN.ADVANCED.CUSTOM.INTEGRATION.TEXT': 'Integrate Calizy with any tool you are using.',
    'SETTINGS.MENU.ACCOUNT.PLAN.ENTERPRISE.TITLE': 'Take Calizy to the next level 💫 ',
    'SETTINGS.MENU.ACCOUNT.PLAN.ENTERPRISE.TEXT1': 'Do you have a specific feature need? A suggestion on how we can improve your Calizy experience?',
    'SETTINGS.MENU.ACCOUNT.PLAN.ENTERPRISE.TEXT2': 'Let\'s get in touch to discuss it!',
    'SETTINGS.MENU.ACCOUNT.SUB.ACCOUNT.LIST.URL': 'Sub-account list and url',
    'SETTINGS.MENU.MANAGERS': 'Managers',
    'SETTINGS.MENU.ROLES': 'Roles',
    'SETTINGS.MENU.ACTIVE': 'Active',
    'SETTINGS.MENU.ARCHIVED': 'Archived',
    'SETTINGS.MENU.APPT.TYPES': 'Appointment types',
    'SETTINGS.MENU.APPT.REMINDERS': 'Appointment reminders',
    'SETTINGS.MENU.CALENDAR.VIEW': 'Calendar view',
    'SETTINGS.MENU.CUSTOMISATION': 'Customisation',
    'SETTINGS.SEARCH.APPT.PLACEHOLDER': 'Search for an appointment',

    'SETTINGS.APPOINTMENT.TYPE.ACTIVE.EMPTY': 'No active appointment type',
    'SETTINGS.APPOINTMENT.TYPE.ARCHIVED.EMPTY': 'No archived appointment type',
    'SETTINGS.APPOINTMENT.TYPE.VISIBLE.TO.ALL': 'Visible to all',
    'SETTINGS.APPOINTMENT.TYPE.VISIBLE.WITH.LINK': 'Only visible with a link',

    'SETTINGS.ROLE.CONSULT': 'Consult',
    'SETTINGS.ROLE.VIEW': 'View',
    'SETTINGS.ROLE.ADD': 'Add',
    'SETTINGS.ROLE.EDIT': 'Edit',
    'SETTINGS.ROLE.LIST': 'List',
    'SETTINGS.ROLE.READ': 'Read',
    'SETTINGS.ROLE.CANCEL': 'Cancel',
    'SETTINGS.ROLE.DELETE': 'Delete',
    'SETTINGS.ROLE.CREATE': 'Create',
    'SETTINGS.ROLE.BOOK': 'Book',

    'SETTINGS.ROLE.RIGHTS.TITLE': 'Configuration',
    'SETTINGS.ROLE.NAME.TITLE': 'Role name',

    'SETTINGS.ROLE.RIGHTS.ACCOUNT': 'Account',
    'SETTINGS.ROLE.RIGHTS.ADVISOR': 'Advisor',
    'SETTINGS.ROLE.RIGHTS.ANONYMOUS_CALENDAR': 'Anonymous calendar',
    'SETTINGS.ROLE.RIGHTS.APPOINTMENT_CATEGORY': 'Appointment types',
    'SETTINGS.ROLE.RIGHTS.APPOINTMENT_NOTIFICATION': 'Appointment notification',
    'SETTINGS.ROLE.RIGHTS.APPOINTMENT': 'Appointment',
    'SETTINGS.ROLE.RIGHTS.BRANCH': 'Branch',

    'SETTINGS.ROLE.RIGHTS.TECHNICIAN.CALENDAR': 'Technician Calendar',
    'SETTINGS.ROLE.RIGHTS.ADVISOR.CALENDAR': 'Advisor Calendar',
    'SETTINGS.ROLE.RIGHTS.COMPANY_CALENDAR': 'Company Calendar',
    'SETTINGS.ROLE.RIGHTS.HUB_BRANCH_CALENDAR': 'Hub/Branch Calendar',
    'SETTINGS.ROLE.RIGHTS.HUB_CALENDAR': 'Hub Calendar',
    'SETTINGS.ROLE.RIGHTS.BRANCH_CALENDAR': 'Branch Calendar',
    'SETTINGS.ROLE.RIGHTS.CALENDAR': 'Calendar',

    'SETTINGS.ROLE.RIGHTS.CALENDAR.VIEW': 'Calendar view',
    'SETTINGS.ROLE.RIGHTS.GENERIC.URLS': 'Generic urls',
    'SETTINGS.ROLE.RIGHTS.COMPANY': 'Company',
    'SETTINGS.ROLE.RIGHTS.HUB': 'Hub',
    'SETTINGS.ROLE.RIGHTS.KPI': 'KPI',
    'SETTINGS.ROLE.RIGHTS.MANAGER': 'Manager',
    'SETTINGS.ROLE.RIGHTS.RIGHTS': 'Roles',
    'SETTINGS.ROLE.RIGHTS.SETTINGS': 'Settings',
    'SETTINGS.ROLE.RIGHTS.TECHNICIAN': 'Technician',
    'SETTINGS.ROLE.RIGHTS.ONBOARDING': 'Onboarding',
    'SETTINGS.ROLE.RIGHTS.SHIFTS': 'Shifts',
    'SETTINGS.ROLE.RIGHTS.SERVICE_POINTS': 'Service points',

    'SETTINGS.ROLE.UPDATE.SUCCESSFULLY': 'Role updated successfully',
    'SETTINGS.ROLE.CREATE.SUCCESSFULLY': 'Role created successfully',
    'SETTINGS.ROLE.DELETE.SUCCESSFULLY': 'Role successfully deleted',
    'SETTINGS.ROLE.DELETE.TITLE': 'Delete the role',
    'SETTINGS.ROLE.DELETE.SUB': 'Are you sure you want to delete this role?',

    'SETTINGS.ROLE.GROUP.ONBOARDING': 'Onboarding',
    'SETTINGS.ROLE.GROUP.BOOKING_FLOW': 'Booking flow',
    'SETTINGS.ROLE.GROUP.RESOURCES': 'Resources',
    'SETTINGS.ROLE.GROUP.CONFIGURATION': 'Configuration',
};
