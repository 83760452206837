<template>
<div
    style='align-items: start'
    class='h-flex h-flex-center h-pos-rel h-width-100p qa-autocomplete'
    :class='{
        "h-mb-30": !noMargin,
        "h-flex-dir-column": isDirectionColumn,
    }'>
    <div v-if='isGeoScopeInternationalWithCountries && isCountrySelector'
         style='width: 320px'
         class='b-address-branch--select fw-select-base h-mr-20'>
        <FwFormInput
            labelType='label-medium'
            :class='{
                "h-mb-25": !noMargin,
            }'
            :title='`${$t(`LABEL.COUNTRY`)}${requiredSymbol}`'>
            <FwSelect
                v-model='country'
                class='fw-select-white qa-autocomplete-select-country'
                :propsPlaceholder='$t(`LABEL.SELECT.COUNTRY`)'
                type='white'
                label='name'
                :disabled='isDisabled'
                :searchable='false'
                :canNotDelete='true'
                :options='geoScopeCountries'
                @select='update'>
            </FwSelect>
        </FwFormInput>
    </div>
    <FwFormInput
        class='h-width-100p'
        :style='selectAddressStyle'
        :class='{
            "h-flex-1-0": isFullSpace,
            "h-mb-25": !noMargin,
            "h-mt-25": isDirectionColumn,
            "b-select-address--wrapper": isGeoScopeInternationalWithCountries,
        }'
        labelType='label-medium'
        :title='`${$t("SELECT.ADDRESS.APPOINTMENT.LABEL")}${requiredSymbol}`'>
        <SelectAddress
            :class='{ "b-direction-column": isDirectionColumn }'
            :startAddress='startAddress'
            :autofocus='isAutoFocus'
            :is-disabled='(isGeoScopeInternationalWithCountries && !country && isCountrySelector) || isDisabled'
            :country='country'
            :isCountrySelector='isCountrySelector'
            :postCodeNotNeed='postCodeNotNeed'
            :useDefaultCountryIso='useDefaultCountryIso'
            @sendPlace='sendPlace'
            @changePostCode='changePostcode'
            @input='input'>
        </SelectAddress>
    </FwFormInput>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Mixins } from 'vue-property-decorator';

import PermissionsMixin from '@/mixins/permissions';

import { SelectAddress } from '@/components/common/SelectAddress';

import { GoogleMapPlaceType, ParsedAddressData } from '@/types/GoogleMap';
import { Country } from '@/types/Account';

@Component({
    components: {
        SelectAddress,
    },
})
export default class AutocompleteSelector extends Mixins(PermissionsMixin) {
    @Prop({ type: String, default: null }) readonly startAddress!: string | null;
    @Prop({ type: String, default: '*' }) readonly requiredText!: string;
    @Prop({ type: Boolean, default: false }) readonly isFullSpace!: boolean;
    @Prop({ type: Boolean, default: false }) readonly isDisabled!: boolean;
    @Prop({ type: Boolean, default: false }) readonly isAutoFocus!: boolean;
    @Prop({ type: Boolean, default: false }) readonly isRequired!: boolean;
    @Prop({ type: Boolean, default: false }) readonly noMargin!: boolean;
    @Prop({ type: Boolean, default: false }) readonly isDirectionColumn!: boolean;
    @Prop({ type: Boolean, default: false }) readonly postCodeNotNeed!: boolean;
    @Prop({ type: Boolean, default: true }) readonly isCountrySelector!: boolean;
    @Prop({ type: Boolean, default: false }) readonly useDefaultCountryIso!: boolean;
    @Prop({ type: Boolean, default: null }) readonly countryData!: Country | null;

    country: Country | null = null;

    get selectAddressStyle() {
        return this.isFullSpace ? 'width: 100%' : 'width: 440px';
    }

    get requiredSymbol() {
        return this.isRequired ? this.requiredText : '';
    }

    mounted() {
        if (this.countryData) {
            this.country = this.countryData;
        }
    }

    input(address: ParsedAddressData) {
        this.$emit('input', address);
    }

    sendPlace(place: GoogleMapPlaceType) {
        this.$emit('sendPlace', place);
    }

    update(place: Country) {
        this.$emit('update', place);
    }

    changePostcode(code: string) {
        this.$emit('changePostCode', code);
    }
}
</script>
<style lang='sass'>
    .b-select-address
        &--wrapper
            .b-select-address
                position: unset!important
            .multiselect
                position: unset!important
                .multiselect__content-wrapper
                    max-height: 200px!important
                    left: 0
                    top: 70px!important
            .b-direction-column
                .multiselect__content-wrapper
                    max-height: 200px!important
                    left: 0
                    top: 175px!important

</style>
