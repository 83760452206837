import axios from 'axios';
import WebApi from '../WebApi';

interface AddressFormDataType {
    q: string,
    iso?: string,
    advisor?: boolean,
}

interface PlaceFormDataType {
    id: string,
}

class AutocompleteWebApi extends WebApi {
    async getAddresses(address: AddressFormDataType) {
        const { data } = await axios.post(`${this.urlPrefix}/ac_addresses`, { address });
        return data.addresses;
    }
    async getFrAddresses(address: AddressFormDataType) {
        const { data } = await axios.post(`${this.urlPrefix}/ac_fr_addresses`, { address });
        return data.addresses;
    }

    async fetchPlaceById(place: PlaceFormDataType) {
        const { data } = await axios.post(`${this.urlPrefix}/ac_addresses/fetch`, { place });
        return data.place;
    }
}

const autocompleteWebApi = new AutocompleteWebApi();

export {
    autocompleteWebApi as AutocompleteWebApi,
};
