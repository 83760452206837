export default {
    'BUTTON.NEXT': 'Suivant',
    'BUTTON.SAVE': 'Enregistrer',
    'BUTTON.ADD': 'Ajouter',
    'BUTTON.SEARCH': 'Rechercher',
    'BUTTON.EDIT': 'Éditer',
    'BUTTON.ADD.NEW': 'Ajouter un nouveau',
    'BUTTON.CONFIRM': 'Confirme',
    'BUTTON.CONFIRM2': 'Confirmer',
    'BUTTON.CONFIRM.HOURS': 'Confirmer ces horaires de travail',
    'BUTTON.ADD.MORE': 'Ajouter',
    'BUTTON.SORT_BY': 'Trier par',
    'BUTTON.SUBMIT': 'Confirmer',
    'BUTTON.BACK': 'Retour',
    'BUTTON.LATER': 'Plus tard',
    'BUTTON.CANCEL': 'Annuler',
    'BUTTON.CONSULT.RECAP': 'Сonsulter le récapitulatif',
    'BUTTON.SKIP': 'Sauter',
    'BUTTON.SKIP.DIRECT': 'Manuellement',
    'BUTTON.CLEAR': 'Tout effacer',
    'BUTTON.CLEAR.ALL': 'Tout effacer',
    'BUTTON.PASS': 'Passer',
    'BUTTON.QUIT': 'Quitter l\'agenda',
    'BUTTON.CREATE': 'Créer',
    'BUTTON.MONTH_CALENDAR': 'Calendrier mois',
    'BUTTON.SEE_CALENDAR': 'Voir l\'agenda',
    'BUTTON.UNLOCK': 'Accéder',
    'HEADER.PLAN': 'Planifier',
    'HEADER.CONSULT': 'Consulter',
    'HEADER.CANCEL': 'Annuler',
    'HEADER.SETTINGS': 'Réglages',
    'HEADER.HELP': 'Aide',
    'HEADER.INFORMATION': 'Information',
    'LABEL': 'Rôle',
    'LABEL.COMPANY': 'Société',
    'LABEL.NUMBER_ADVISOR_SERVICE_POINT': 'Nombre maximum de conseillers par Permanence',
    'LABEL.BUSINESS.SECTOR': 'Secteur d\'activité',
    'LABEL.ADDRESS': 'Adresse',
    'LABEL.ADDRESS.POST.CODE': 'Le code postal de cette adresse n’existe pas, merci de taper le code postal ou de modifier l’adresse',
    'LABEL.ADDRESS.1': 'Adresse 1',
    'LABEL.ADDRESS.2': 'Adresse 2',
    'LABEL.ZIP.CODE': 'Code Postal',
    'LABEL.CODE.INSEE': 'Code INSEE',
    'LABEL.CITY': 'Ville',
    'LABEL.CODE.SIREN': 'SIREN',
    'LABEL.CODE.SIRET': 'SIRET',
    'LABEL.PHONE.NUMBER': 'Téléphone',
    'LABEL.EMAIL': 'Email',
    'LABEL.SERVICE_POINT_CAPACITY': 'Limite de conseillers par jour',
    'LABEL.EMAIL.ADDRESS': 'Adresse email',
    'LABEL.NOTES': 'Remarques',
    'LABEL.MANAGER': 'Manager',
    'LABEL.SELECT.PLACEHOLDER': 'Sélectionnez une date',
    'LABEL.ADVISOR': 'Conseiller',
    'LABEL.TECHNICIANS': 'Ressources',
    'LABEL.TECHICIANS.NUMBER': 'Nombre de ressources',
    'LABEL.EMPLOYEES.NUMBER': 'Nombre d’employés',
    'LABEL.ROOMS': 'Pièces',
    'LABEL.HOURS': 'heures',
    'LABEL.MINUTES': 'minutes',
    'LABEL.NO.IMAGE': 'Aucune Image',
    'LABEL.AT': 'à',

    'LABEL.TEAM.NAME': 'Team\'s name',
    'LABEL.TEAM.PLACEHOLDER': 'Team\'s name',

    'LABEL.REQUIRED.FIELD': 'Champs obligatoires',

    'LABEL.SEARCH.BY.LAST': 'Rechercher par nom',

    'LABEL.COMPANY.NAME': 'Nom de la société',
    'LABEL.COMPANIES': 'Sociétés',
    'LABEL.WORKERS': 'Ressources',
    'LABEL.WORKERS.PLURAL': 'Ressource | Ressources',
    'LABEL.YOUR.COMPANY': 'Votre compte',
    'LABEL.NAME': 'Nom',
    'LABEL.SERVICE_POINT.ID': 'ID Permanence',
    'LABEL.TOPIC.TO.DISCUSS': 'Sujets à aborder',
    'LABEL.BRANCH.ID': 'Code agence',
    'LABEL.ID': 'Id',
    'LABEL.EXTERNAL.ID': 'ID externe',
    'LABEL.APPOINTMENT.METHOD': 'Mode de RDV',
    'LABEL.APPOINTMENT.METHODS': 'Modes de RDV',
    'LABEL.ADVISOR.BY.DEFAULT': 'Ce conseiller est par défaut:',
    'LABEL.GEOGRAFIC.ZONE': 'Zone géographique',
    'LABEL.LINK': 'Lien',
    'LABEL.METHOD': 'Mode',
    'LABEL.METHODS': 'Mode',
    'LABEL.CONTACT': 'Contact',
    'LABEL.LOGO': 'Logo',
    'LABEL.QUALIFICATIONS': 'Habilitations',
    'LABEL.ADDRESS.TWO': 'Adresse 2',
    'LABEL.START.POINT': 'Point de départ',
    'LABEL.PHONE': 'Téléphone',
    'LABEL.ADMIN': 'Admin',
    'LABEL.GEOGRAPHIC.ZONE': 'Geographic zone',
    'LABEL.GEOGRAPHIC.ZONE.CITIES': 'Sélectionner des communes',
    'LABEL.GEOGRAPHIC.ZONE.DISTRICTS': 'Sélectionner des départements',
    'LABEL.GEOGRAPHIC.ZONE.DISTRICTS.SEARCH': 'Rechercher un département',
    'LABEL.DELETE': 'SUPPRIMER',
    'LABEL.SKILLS': 'Compétences',
    'LABEL.SKILL': 'Compétence',
    'LABEL.SKILLS.NEEDED': 'Compétences requises',
    'LABEL.LOCATION.APPT': 'Mode',
    'LABEL.NO': 'Non',
    'LABEL.YES': 'Oui',
    'LABEL.PLACEHOLDER.SEARCH': 'Recherche',
    'LABEL.PLACEHOLDER.APPOINTMENT': 'Donnez un nom à votre rendez-vous',
    'LABEL.DISPLAY.PARAMETERS': 'Paramètres d\'affichage',
    'LABEL.APP.AVAILABILITY': 'Disponibilité dans les apps',
    'LABEL.APP.VISIBILITY': 'Visibilité dans Calizy Selfcare',
    'LABEL.MAIN': 'Titre principal',
    'LABEL.SELFCARE.PLACEHOLDER': 'Titre dans Calizy Selfcare',
    'LABEL.DISPLAY.ORDER': 'Ordre d\'affichage',
    'LABEL.ORDER.PLACEHOLDER': 'ex: 1',
    'LABEL.PLACEHOLDER.CHOOSE_LANG': 'Choisissez la langue',
    'LOADING.TEXT': 'CHARGEMENT...',
    'LABEL.PASSWORD': 'Mot de passe',
    'LABEL.BOOK': 'Confirmer',
    'LABEL.NO.RESULT': 'Aucun résultat',
    'LABEL.ALL': 'Tous les',
    'LABEL.ALL.ADVISORS': 'conseillers',
    'LABEL.RESULT.HERE.INFO': 'Les résultats vont s\'afficher ici',
    'LABEL.PERSONALISED.LINK': 'Liens Personnalisés',
    'LABEL.AUTOMATIC.LINK': 'Liens Automatiques',

    'TITLE.MANAGER.ADVANCED.SEARCH': 'Recherche avancée de manager',
    'TITLE.ADVISOR.ADVANCED.SEARCH': 'Recherche avancée de conseiller',
    'LABEL.TYPE.FIRST.NAME': 'Saisissez le prénom',
    'LABEL.TYPE.LAST.NAME': 'Saisissez le nom',
    'LABEL.TYPE.EMAIL': 'Saisissez l’email',
    'LABEL.SELECT.SKILL': 'Sélectionnez une compétence',
    'LABEL.SELECT.APPOINTMENT.METHOD': 'Sélectionnez un mode de rdv',
    'LABEL.SELECT.ROLE': 'Sélectionnez une fonction',
    'LABEL.SUB.ACCOUNTS': 'Sous-comptes',
    'LABEL.SELECT.SUB.ACCOUNTS': 'Sélectionnez un ou plusieurs sous-comptes',
    'LABEL.SUB.ACCOUNT': 'Sous-compte',
    'LABEL.SELECT.SUB.ACCOUNT': 'Sélectionnez un sous-compte',

    'FINISH.POPUP.SUCCESS.TITLE': 'Annulation réussie !',
    'FINISH.POPUP.SUCCESS.CLOSE': 'Fermer',
    'VALIDATION.FIELD.REQUIRED': 'Ce champ est obligatoire',
    'VALIDATION.FIELD.EMAIL': 'Email invalide',
    'VALIDATION.FIELD.CHARACTERS.MAX': 'Ne doit pas dépasser 10 caractères',
    'VALIDATION.FIELD.CHARACTERS.REQUIRED': 'Doit contenir au moins 2 caractères',
    'VALIDATION.FIELD.PASSWORD.MIN': 'Le mot de passe doit contenir au moins 6 caractères',
    'WRONG.CODE': 'Veuillez saisir un code d\'intervention valide',

    'VALIDATION.EMAIL_OR_PHONE.REQUIRED': 'Email ou téléphone est requis',
    'VALIDATION.FIRST_OR_LAST_NAME.REQUIRED': 'Le prénom ou le nom est requis',

    'LABEL.CREATE': 'Créer',
    'LABEL.DESTROY': 'Effacer',
    'LABEL.LIST': 'Liste',
    'LABEL.READ': 'Lis',
    'LABEL.EDIT': 'Éditer',
    'LABEL.HAVE.NOT.DATA.FOR.PERIOD': 'Il n\'y a pas de donnée pour la période sélectionnée.',
    'LABEL.HAVE.NOT.DATA.FOR.POST_CODE': 'Il n\'y a pas de donnée pour le code postal sélectionné.',
    'LABEL.VIDEO.CONFERENCE.URL': 'Lien de visioconférence',

    'LABEL.APPOINTMENT.TYPE': 'Type de rendez-vous',
    'LABEL.NEW.APPOINTMENT': 'Nouveau rendez-vous',
    'LABEL.SEARCH.CLIENT': 'Rechercher un client',
    'LABEL.BY.PROSPECT.CUSTOMER': 'Par ID prospect/client',
    'LABEL.BY.CLIENT.EMAIL': 'Par email client',
    'LABEL.BY.CLIENT.PHONE': 'Par téléphone client',
    'LABEL.CATEGORY': 'Catégorie',
    'LABEL.TYPE': 'Type',
    'LABEL.SELECT.TYPE': 'Choisissez un type',

    'GLOBAL.TECHNICIAN': 'Ressource',

    'GLOBAL.ELEMENT.PLURAL': 'Élément | Éléments',
    'GLOBAL.TYPE': 'Type',
    'GLOBAL.RECIPIENT': 'Bénéficiaire',
    'GLOBAL.WHEN': 'Quand',
    'GLOBAL.CATEGORY': 'Catégorie',
    'GLOBAL.TITLE': 'Titre',
    'GLOBAL.DURATION': 'Durée',
    'GLOBAL.SKILLS.METHOD': 'Compétence',
    'GLOBAL.METHOD': 'Méthode',
    'GLOBAL.SYNCHRONISATION': 'Synchronisations',

    'HEADER.EDIT': 'Modifier un informations',
    'LABEL.GEO.ZONE': 'ajouter un département/une ville',

    'NOTIFICATION.BAR.SUCCESS.CREATED': 'Ajout enregistré',
    'NOTIFICATION.BAR.SUCCESS.UPDATED': 'Modification enregistrée',
    'NOTIFICATION.BAR.SUCCESS.BAR.REMOVED': 'Suppression enregistrée',
    'NOTIFICATION.BAR.SUCCESS.CONFIRMED': 'Confirmation enregistrée',
    'NOTIFICATION.BAR.SUCCESS.CANCELED': 'Suppression enregistrée',
    'ERROR.IMAGE.FORMAT': 'Le format de ce fichier n\'est pas supporté. Les formats acceptés sont : PNG, JPEG',
    'ERROR.IMAGE.MAXLIMIT': 'La taille du fichier est trop importante. La taille maximum du fichier supportée est 3mb',

    'LABEL.CALIZY_SAAS_APPT': 'rendez-vous Calizy SaaS | rendez-vous Calizy SaaS',
    'LABEL.CALIZY_SELFCARE_APPT': 'rendez-vous Calizy Selfcare | rendez-vous Calizy Selfcare',
    'LABEL.UNAVAILABILITY': 'indisponibilité | indisponibilités',
    'LABEL.RESERVED_SLOT': 'Créneaux réservé | Créneaux réservés',
    'GLOBAL.FROM': 'Du',
    'GLOBAL.TO': 'Au',

    'LABEL.COUNTRY': 'Pays',
    'LABEL.COUNTRY.ISO': 'Pays',
    'LABEL.SELECT.COUNTRY': 'Sélectionnez un pays',
};
