<template>
<div class='h-width-100p b-form-tabs-main'>
    <div class='b-form-tabs h-mb-50'>
        <div
            v-for='(tab, index) in tabs'
            ref='activeTabRef'
            :key='tab.name'
            :class='{
                "b-form-tabs__active-tab": tab.isActive,
                "b-pointer-none": loading,
            }'
            class='b-form-tabs__tab h-fw-500 h-text-center'
            @click='selectTab(tab, index)'>
            {{ tab.name }}
        </div>
        <div class='h-pos-abs b-form-tabs__line-active' :style='{ left: tabLeft + "px", width: tabWidth + "px" }'>
            <div class='b-form-tabs__line-active-box'></div>
        </div>
        <div class='b-form-tabs__line'></div>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Vue } from 'vue-property-decorator';

import { GeograficTabType } from '@/types/Appointment';
import { Country } from '@/types/Account';

@Component({
    components: {
    },
})
export default class GeograficFormTabs extends Vue {
    @Prop({ type: Array, required: true }) readonly countries!: Array<Country>;
    @Prop({ type: Boolean, default: false }) readonly loading!: boolean;
    country: string = 'France';
    tabs: Array<Country> | null = null;

    tabWidth: number = 100;
    tabLeft: number = 100;
    activeTabIndex: number = 0;

    $refs!: {
        activeTabRef: Array<HTMLElement>
    };

    selectTab(selectedTab: Country, index: number) {
        this.activeTabIndex = index;
        this.updateTabWidth();
        this.country = selectedTab.name;
        this.$emit('updateCurrentTab', selectedTab.country_iso);
    }

    created() {
        if (this.countries && this.countries.length) {
            this.tabs = this.countries.sort((a, b) => {
                if (a.name === 'France') return -1;
                if (b.name === 'France') return 1;
                return 0;
            });
        }
    }

    mounted() {
        this.updateTabWidth();
    }

    updateTabWidth() {
        if (this.$refs && this.$refs.activeTabRef) {
            this.tabWidth = this.$refs.activeTabRef[this.activeTabIndex].offsetWidth;
            this.tabLeft = this.$refs.activeTabRef[this.activeTabIndex].offsetLeft;
        }
    }
}
</script>

<style lang='sass'>
    .b-form-tabs
        display: flex
        justify-content: center
        align-items: center
        color: $dark-blue
        &__line
            position: absolute
            bottom: -14px
            width: 100%
            height: 1px
            background: $dark-blue
            &-active
                display: flex
                justify-content: center
                position: absolute
                top: 30px
                transition: left 0.5s
                &-box
                    height: 2px
                    width: 50px
                    background:  $aqua-marine-light
        &__active-tab
            position: relative
            display: flex
            justify-content: center
            align-items: center
            flex-flow: column nowrap
            color: $aqua-marine-light
        &__tab
            cursor: pointer
            margin: 0 30px
    .b-form-tabs-main
        .component-fade-enter-active, .component-fade-leave-active
            transition: opacity 0.2s

        .component-fade-enter, .component-fade-leave-to
            opacity: 0
</style>
