var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"h-flex h-flex-center h-pos-rel h-width-100p qa-autocomplete",class:{
        "h-mb-30": !_vm.noMargin,
        "h-flex-dir-column": _vm.isDirectionColumn,
    },staticStyle:{"align-items":"start"}},[(_vm.isGeoScopeInternationalWithCountries && _vm.isCountrySelector)?_c('div',{staticClass:"b-address-branch--select fw-select-base h-mr-20",staticStyle:{"width":"320px"}},[_c('FwFormInput',{class:{
                "h-mb-25": !_vm.noMargin,
            },attrs:{"labelType":"label-medium","title":`${_vm.$t(`LABEL.COUNTRY`)}${_vm.requiredSymbol}`}},[_c('FwSelect',{staticClass:"fw-select-white qa-autocomplete-select-country",attrs:{"propsPlaceholder":_vm.$t(`LABEL.SELECT.COUNTRY`),"type":"white","label":"name","disabled":_vm.isDisabled,"searchable":false,"canNotDelete":true,"options":_vm.geoScopeCountries},on:{"select":_vm.update},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}})],1)],1):_vm._e(),_c('FwFormInput',{staticClass:"h-width-100p",class:{
            "h-flex-1-0": _vm.isFullSpace,
            "h-mb-25": !_vm.noMargin,
            "h-mt-25": _vm.isDirectionColumn,
            "b-select-address--wrapper": _vm.isGeoScopeInternationalWithCountries,
        },style:(_vm.selectAddressStyle),attrs:{"labelType":"label-medium","title":`${_vm.$t("SELECT.ADDRESS.APPOINTMENT.LABEL")}${_vm.requiredSymbol}`}},[_c('SelectAddress',{class:{ "b-direction-column": _vm.isDirectionColumn },attrs:{"startAddress":_vm.startAddress,"autofocus":_vm.isAutoFocus,"is-disabled":(_vm.isGeoScopeInternationalWithCountries && !_vm.country && _vm.isCountrySelector) || _vm.isDisabled,"country":_vm.country,"isCountrySelector":_vm.isCountrySelector,"postCodeNotNeed":_vm.postCodeNotNeed,"useDefaultCountryIso":_vm.useDefaultCountryIso},on:{"sendPlace":_vm.sendPlace,"changePostCode":_vm.changePostcode,"input":_vm.input}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }