import { render, staticRenderFns } from "./EditAddressForm.vue?vue&type=template&id=537854b6"
import script from "./EditAddressForm.vue?vue&type=script&lang=ts"
export * from "./EditAddressForm.vue?vue&type=script&lang=ts"
import style0 from "./EditAddressForm.vue?vue&type=style&index=0&id=537854b6&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports