<template>
<div class='b-blue-background b-blue-background__opacity b-blue-background--fixed b-urls-popup'>
    <FwPopup
        class='b-geo-zone-reassignments-popup qa-advisor-url-builder-popup'
        :class='{ "b-geo-zone-reassignments-popup--empty": !isLoading && advisorUrls.length }'
        :style='{ padding: `50px`, height: `85%`, }'
        :centered='true'
        :isFixed='true'
        iconColor='#BEC7D4'
        max-width='1420px'
        :title='isSearchActivated ? $tc("Calizy Selfcare links advanced search") : $tc("ADVISOR.SELFCARE.LINKS.PLURAL", 1)'
        :allowBackgroundScrolling='true'
        :showClosePopup='true'
        :padding='isMobile() ? "30px 20px" : "50px"'
        :disableClosingOnClickOutside='true'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        @click.native.stop
        @close='$emit("close")'>
        <template #body>
            <div class='b-geo-zone-reassignments-popup__inner'>
                <div v-if='isSearchActivated'
                     class='b-back-button h-inline-flex h-pointer'
                     style='top: 50px'
                     @click='onBack'>
                    <FwIcon
                        class='h-flex-center h-pointer'
                        icon='arrow-left'
                        size='14'
                        color='rgba(33,63,107,.3)'>
                    </FwIcon>
                    <p class='h-bold h-mv-0 h-ml-10 h-font-14'>
                        {{ $t('BUTTON.BACK') }}
                    </p>
                </div>
                <div v-if='!isSearchActivated' class='h-flex h-flex-justify-end h-mt-25'>
                    <FwIcon
                        class='h-flex-center h-pointer h-mr-25'
                        icon='loupe'
                        size='18'
                        color='#213F6B'
                        @click.native='isSearchActivated = true'>
                    </FwIcon>
                    <div></div>
                    <FwButton
                        v-if='showSelfcareLinkAction'
                        borderRadiusType='small-border qa-advisor-url-builder-add'
                        size='little'
                        @click.native='isSelfcareBookingPopupShow = true'>
                        {{ $t('BUTTON.ADD') }}
                    </FwButton>
                </div>
                <div class='b-geo-zone-reassignments-popup__table__wrapper'>
                    <TabsDefault
                        v-if='!isSearchActivated'
                        :tabs='tabsData'
                        :kind='kind'
                        @updateCurrentTab='loadDataTab'>
                    </TabsDefault>
                    <SearchFields v-if='isSearchActivated' @getAdvisorsUrls='getAdvisorsUrls'></SearchFields>

                    <FwSpinLoader
                        v-if='isLoading'
                        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
                        class='h-flex-center loader'
                        :isActive='isLoading'
                        className='h-p-20'>
                    </FwSpinLoader>
                    <FwHorizontalScroll
                        v-if='!isLoading && advisorUrls.length'
                        :isPageLoaded='true'
                        slotWrapperClass='h-flex h-flex-dir-column h-height-auto-force h-mt-30'
                        iconClass='h-opacity-1'
                        newClass='fw-arrow-big-circle-fixed'
                        :arrowBgWidth='null'
                        colorIcon='#fff'
                        btnLocation='between'
                        hideBtn>
                        <template #content>
                            <CalendarTable
                                :titles='titles'
                                :data='advisorUrls'
                                :bigIndexes='[]'
                                maxWidth='100%'
                                class='b-calendar__table__wrapper'>
                                <div v-for='(item, i) in advisorUrls'
                                     :key='i'
                                     class='b-calendar__table'>
                                    <div class='b-calendar__table__outer'>
                                        <div v-for='(key, j) in mapping'
                                             :key='j'
                                             class='b-calendar__table__item'>
                                            <template v-if='item[key] && item[key].length'>
                                                <span class='h-overflow-text'>
                                                    {{ item[key] }}
                                                </span>
                                            </template>
                                        </div>
                                    </div>
                                    <div class='b-calendar__table__buttons'>
                                        <div class='b-calendar__table__item__delete h-ml-10 h-pointer h-width-60'
                                             @click='copyLink(item)'>
                                            <FwIcon
                                                class='h-flex-center h-pointer'
                                                icon='copy-document'
                                                size='18'
                                                color='rgba(33,63,107,.3)'>
                                            </FwIcon>
                                        </div>
                                        <div v-if='isPersonalized'
                                             class='b-calendar__table__item__delete h-ml-10 h-pointer h-width-60'
                                             @click='showDeleteGeoZone(item)'>
                                            <FwIcon
                                                class='h-flex-center h-pointer'
                                                icon='bucket-edit'
                                                size='18'
                                                color='rgba(33,63,107,.3)'>
                                            </FwIcon>
                                        </div>
                                    </div>
                                </div>
                            </CalendarTable>
                        </template>
                    </FwHorizontalScroll>
                    <div v-else-if='!isLoading'
                         class='b-geo-zone-reassignments-popup__empty-text'>
                        {{ $t('ADVISOR.SELFCARE.NO_LINKS') }}
                    </div>
                    <FwPagination
                        v-if='totalPage > perPage'
                        style='bottom: 0'
                        :totalPage='totalPage'
                        :currentPage='currentPage'
                        :perPage='perPage'
                        :value='perPage'
                        @getData='getData'>
                    </FwPagination>
                </div>
            </div>
        </template>
    </FwPopup>
    <CalizySelfcateAddUrlPopup
        v-if='isSelfcareBookingPopupShow'
        @updateUrlsList='updateValue'
        @close='isSelfcareBookingPopupShow = false'>
    </CalizySelfcateAddUrlPopup>
    <DeletePopup
        v-if='isDeletePopupShow'
        :headerText='$t("ADVISOR.SELFCARE.DELETE.LINK.TITLE")'
        :subMessage='$t("ADVISOR.SELFCARE.DELETE.LINK.SUBTITLE")'
        :isRequestSending='isDeleteLoading'
        @deleteItem='deleteUrl'
        @close='isDeletePopupShow = false'>
    </DeletePopup>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { AppointmentWebApi } from '@/api/appointment/AppointmentApi';
import {
    AppointmentAdvisorUrlsBuilderType,
    AppointmentCategoryType,
    UrlBuildersFilterType,
} from '@/types/Appointment';
import { BaseMetaType } from '@/types/Response';
import { TabsType } from '@/types/Tabs';
import { SubAccountType } from '@/types/Account';

import { CalendarTable } from '@/components/common/CalendarTable';
import { CalizySelfcateAddUrlPopup } from '@/components/popups/CalizySelfcateAddUrlPopup';
import { DeletePopup } from '@/components/popups/DeletePopup';
import { TabsDefault } from '@/components/forms/tabs/TabsDefault';
import { SearchFields } from '@/components/forms/search/SearchFields';

const CommonStore = namespace('CommonStore');

@Component({
    components: {
        TabsDefault,
        CalendarTable,
        CalizySelfcateAddUrlPopup,
        DeletePopup,
        SearchFields,
    },
})
export default class CalizySelfcareLinksPopup extends Vue {
    @CommonStore.Getter('subAccounts') subAccountsFromAccount!: Array<SubAccountType>;
    @Prop({ type: Number, required: true }) readonly updateListKey!: number;

    advisorUrls: Array<AppointmentAdvisorUrlsBuilderType> = [];
    urlItem: AppointmentAdvisorUrlsBuilderType | null = null;
    appointmentCategories: Array<AppointmentCategoryType> = [];
    urlBuilderData: null = null;

    isAddingLoading: boolean = false;
    isDeleteLoading: boolean = false;
    isDeletePopupShow: boolean = false;
    isSelfcareBookingPopupShow: boolean = false;
    isLoading: boolean = false;
    isSearchActivated: boolean = false;
    currentPage: number = 1;
    totalPage: number = 0;
    perPage: number = 5;
    meta: BaseMetaType | null = null;
    tabsData: Array<TabsType> = [{
        name: 'LABEL.PERSONALISED.LINK',
        id: 'personalized',
    }, {
        name: 'LABEL.AUTOMATIC.LINK',
        id: 'auto',
    }];
    kind: string = 'personalized';

    get isPersonalized() {
        return this.kind === 'personalized';
    }

    get titles(): Array<string> {
        const values = [
            'LABEL.CATEGORY',
            'LABEL.TYPE',
            'LABEL.METHOD',
            'LABEL.LINK',
        ];

        if (this.subAccountsFromAccount && this.subAccountsFromAccount.length) {
            values.unshift('LABEL.SUB.ACCOUNT');
        }

        return values;
    }

    get mapping(): Array<string> {
        const values = [
            `appointment_category_title`,
            `appointment_category_kind`,
            `location_name`,
            this.isPersonalized ? `tiny_url` : `full_url`,
        ];
        if (this.subAccountsFromAccount && this.subAccountsFromAccount.length) {
            values.unshift('sub_account_name');
        }

        return values;
    }

    get showSelfcareLinkAction(): boolean {
        return this.currentUserRole && this.currentUserRole.advisor_rights && this.currentUserRole.advisor_rights.update;
    }

    getData(currentPage: number, perPage: number = this.perPage): void {
        this.currentPage = currentPage;
        this.perPage = perPage;
        this.getAdvisorsUrls();
    }

    updateMeta(meta: BaseMetaType): void {
        this.meta = meta;
        this.totalPage = meta.total_count;
        this.currentPage = meta.current_page;
    }

    showDeleteGeoZone(item: AppointmentAdvisorUrlsBuilderType): void {
        this.isDeletePopupShow = true;
        this.urlItem = item;
    }

    onBack() {
        this.isSearchActivated = false;
        this.getAdvisorsUrls();
    }

    async loadDataTab(id: string) {
        this.kind = id;
        await this.getAdvisorsUrls();
    }

    async copyLink(item: AppointmentAdvisorUrlsBuilderType) {
        await navigator.clipboard.writeText(this.isPersonalized ? item.tiny_url : item.full_url);
        this.sentNotif(`SELFCARE.YOUR.BOOKING.LINK.COPY.TO.CLIPBOARD`, true);
    }

    async getAdvisorsUrls({ ac_title = '', ac_kind = '', skill_id = '', sub_account_id = '' }: UrlBuildersFilterType = {}): Promise<void> {
        this.isLoading = true;
        try {
            const data = await AppointmentWebApi.getAdvisorUrlsBuilder({
                advisor_id: this.$router.currentRoute.params.id,
                current_page: this.currentPage,
                per_page: this.perPage,
                kind: this.kind,
                skill_id,
                ac_kind,
                ac_title,
                sub_account_id,
            });

            this.advisorUrls = data.advisor_urls;
            this.updateMeta(data.meta);
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.isLoading = false;
        }
    }

    async deleteUrl(): Promise<void> {
        this.isDeleteLoading = true;
        try {
            if (this.urlItem) {
                await AppointmentWebApi.deleteAdvisorUrlBuilder(this.urlItem.id);
                this.currentPage = this.advisorUrls.length === 1 && this.currentPage !== 1 ? this.currentPage - 1 : this.currentPage;
            }
            this.getAdvisorsUrls();
            this.urlItem = null;
            this.isDeletePopupShow = false;
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.isDeleteLoading = false;
        }
    }

    @Watch('updateListKey')
    updateListKeyHandler() {
        this.getAdvisorsUrls();
    }

    updateValue() {
        this.getAdvisorsUrls();
    }

    created() {
        this.getAdvisorsUrls();
    }
}
</script>

<style lang="sass">
.b-urls-popup
    .b-calendar__table__inner .b-calendar__table__wrapper
        width: 100%
    .b-calendar__table__header__item, .b-calendar__table__item
        flex: 2 0 220px
        max-width: 220px
    .b-calendar__table__header__item:last-child, .b-calendar__table__item:last-child
        white-space: nowrap
        flex: 2 0 355px
        max-width: 355px
        span
            overflow: hidden
            text-overflow: ellipsis
    .b-calendar__table__buttons:before
        content: none
</style>
