<template>
<form :style='{ opacity: loading || skillsLoading ? 0 : 1 }'
      @keydown.stop.prevent.enter='addAppointmentData'>
    <div v-if='showTitle'>
        <div class='b-add-edit__form__title'>
            {{ popupTitle }}
        </div>
    </div>
    <div class='h-mt-20 b-add-edit__form__input'>
        <h2 class='h-font-22 h-mt-50 h-mb-30'>
            {{ $t('LABEL.DISPLAY.PARAMETERS') }}
        </h2>
        <label class='fw-form-input__label label-medium'>
            {{ $t('LABEL.APP.AVAILABILITY') }}*
        </label>

        <FwCheckbox
            v-model='checkboxSaaS'
            class='h-mr-10'
            :value='checkboxSaaS'
            type='plus'
            :disabled='disabled'
            text='Calizy SaaS'
            iconRightSide='plus'
            iconCheckedSize='12'
            name='checkbox'>
        </FwCheckbox>
        <FwCheckbox
            v-model='checkboxSelfcare'
            class='h-mr-10'
            :value='checkboxSelfcare'
            type='plus'
            :disabled='disabled'
            text='Calizy Selfcare'
            iconRightSide='plus'
            iconCheckedSize='12'
            name='checkbox'
            @change='filterMethods'>
        </FwCheckbox>
    </div>
    <div
        v-if='checkboxSelfcare && subAccounts && subAccounts.length'
        class='b-add-edit__form__row h-mt-10'>
        <FwFormInput
            class='h-flex-0-0-50p h-ph-15'
            labelType='label-medium'
            :title='`${$t(`LABEL.SUB.ACCOUNTS`)}`'>
            <div class='fw-select-base'>
                <FwSelect
                    v-model='subAccountsChoosed'
                    class='fw-select-with-icon--big'
                    :propsPlaceholder='$t(`LABEL.SELECT.SUB.ACCOUNTS`)'
                    type='white'
                    label='name'
                    name='name'
                    :searchable='false'
                    :disabled='disabled'
                    :multiple='true'
                    :clearable='false'
                    :withCircles='true'
                    :canNotDelete='false'
                    :options='subAccountsOptions'
                    group-values='accounts'
                    group-label='type'
                    :group-select='true'
                    track-by='name'>
                </FwSelect>
            </div>
        </FwFormInput>
    </div>
    <div class='b-add-edit__form__row h-mt-10'>
        <FwFormInput
            class='h-flex-0-0-50p h-ph-15'
            :fieldHasError='fieldHasError("appointment_name")'
            labelType='label-medium'
            :title='`${$t(`LABEL.TITLE`)}*`'>
            <FwInput
                :value='appointment_name'
                type='text'
                name='text'
                :placeholder='$t(`LABEL.MAIN`)'
                inputStyle='white-bigger'
                :disabled='disabled'
                :error='getFieldErrorText("appointment_name")'
                @update:value='value => appointment_name = value'
                @input='clearServerErrorsBase("appointment_name")'
                @blur='addCurrentInputToValidateArray("appointment_name")'>
            </FwInput>
        </FwFormInput>
        <FwFormInput
            class='h-flex-1-0 h-ph-15'
            labelType='label-medium'
            :title='`${$t(`LABEL.CATEGORY`)}*`'>
            <div class='fw-select-base'>
                <FwSelect
                    v-model='appointmentCategory'
                    :propsPlaceholder='$t(`LABEL.CATEGORY.PLACEHOLDER`)'
                    type='white'
                    :disabled='disabled'
                    :searchable='false'
                    :canNotDelete='true'
                    :options='appointmentCategoriesData'>
                </FwSelect>
            </div>
        </FwFormInput>
    </div>
    <div class='b-add-edit__form__row'>
        <FwFormInput
            v-if='checkboxSelfcare'
            class='h-flex-1-0 h-ph-15'
            :fieldHasError='fieldHasError("appointment_name")'
            labelType='label-medium'
            :title='`${$t(`LABEL.SELFCARE.PLACEHOLDER`)}`'>
            <FwInput
                :value='appointment_sc_name'
                type='text'
                name='text'
                :placeholder='$t(`LABEL.SELFCARE.PLACEHOLDER`)'
                inputStyle='white-bigger'
                :disabled='disabled'
                :error='getFieldErrorText("appointment_name")'
                @update:value='value => appointment_sc_name = value'
                @input='clearServerErrorsBase("appointment_name")'
                @blur='addCurrentInputToValidateArray("appointment_name")'>
            </FwInput>
        </FwFormInput>
        <FwFormInput
            v-if='checkboxSelfcare'
            class='h-flex-1-0 h-ph-15'
            labelType='label-medium'
            :title='`${$t(`LABEL.APP.VISIBILITY`)}`'>
            <div class='fw-select-base'>
                <FwSelect
                    v-model='appointmentVisibility'
                    :propsPlaceholder='$t(`LABEL.CATEGORY.PLACEHOLDER`)'
                    type='white'
                    label='title'
                    :disabled='disabled'
                    :searchable='false'
                    :canNotDelete='true'
                    :options='appointmentVisibilityData'>
                </FwSelect>
            </div>
        </FwFormInput>
    </div>
    <div class='b-add-edit__form__row'>
        <FwFormInput
            class='h-flex-0-0-50p h-ph-15'
            :fieldHasError='fieldHasError("custom_order")'
            labelType='label-medium'
            :title='`${$t(`LABEL.DISPLAY.ORDER`)}`'>
            <FwInput
                :value='custom_order'
                type='number'
                name='text'
                :placeholder='$t(`LABEL.ORDER.PLACEHOLDER`)'
                inputStyle='white-bigger'
                :disabled='disabled'
                :error='getFieldErrorText("custom_order")'
                @update:value='value => custom_order = value'
                @input='clearServerErrorsBase("custom_order")'
                @blur='addCurrentInputToValidateArray("custom_order")'>
            </FwInput>
        </FwFormInput>
    </div>
    <div v-if='categoryLocationSkillNames.length'
         class='h-mt-20 b-add-edit__form__input'>
        <h2 class='h-font-22 h-mt-50 h-mb-30'>
            {{ $t('SELECT.BOOKING.PARAMETERS.TITLE') }}
        </h2>
        <label class='fw-form-input__label label-medium'>
            {{ $t('LABEL.METHODS') }}*
        </label>

        <FwCheckbox
            v-for='item in categoryLocationSkillNames'
            :id='item.id'
            :key='item.id'
            v-model='checkboxLocation'
            class='h-mr-10'
            :value='$t(item)'
            type='plus'
            :disabled='isCheckBoxDisabled(item)'
            :text='$t(item[labelKey])'
            iconRightSide='plus'
            iconCheckedSize='12'
            name='checkbox'>
        </FwCheckbox>
    </div>
    <FwSpinLoader
        v-if='skillsLoading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader h-pos-rel h-width-100p'
        :style='{ left: "55px", width: "0px" }'
        :isActive='skillsLoading'
        className='h-p-20 h-mt-15'>
    </FwSpinLoader>
    <div v-else class='b-add-edit__form__row h-mt-10'>
        <FwFormInput
            class='h-flex-1-0 h-ph-15'
            labelType='label-medium'
            :title='`${$t(`LABEL.SKILLS.NEEDED`)}`'>
            <div class='fw-select-base'>
                <FwSelect
                    v-model='skillChoosed'
                    class='fw-select-with-icon--big'
                    :propsPlaceholder='$t(`SKILLS.YOU.NEED.SELECT`)'
                    type='white'
                    :searchable='false'
                    :disabled='disabled'
                    :label='labelKey'
                    :multiple='true'
                    :clearable='false'
                    :withCircles='true'
                    :canNotDelete='false'
                    :options='skills'>
                </FwSelect>
            </div>
        </FwFormInput>
        <FwFormInput
            class='h-flex-1-0 h-ph-15'
            :fieldHasError='fieldHasError("appointment_time")'
            labelType='label-medium'
            :title='`${$t(`LABEL.TIME.TITLE`)}*`'>
            <div class='fw-select-base'>
                <FwSelect
                    v-model='timeFrom'
                    type='white'
                    class='fw-select-with-icon--big'
                    :propsPlaceholder='$t(`LABEL.TIME.TITLE`)'
                    :withCircles='true'
                    :clearable='false'
                    :disabled='disabled'
                    :canNotDelete='true'
                    :options='duration'
                    label='title'>
                </FwSelect>
            </div>
        </FwFormInput>
    </div>

    <div v-if='!disabled'
         class='h-mt-10 h-mb-30 b-required-field'>
        * {{ $t(`LABEL.REQUIRED.FIELD`) }}
    </div>
</form>
</template>

<script lang='ts'>
import { clone } from 'ramda';
import { State, namespace } from 'vuex-class';
import { Component, Watch, Mixins, Prop, Emit } from 'vue-property-decorator';

import i18n from '@/locale';

import {
    CreateAppointmentCategoryPayloadType,
    CategoryLocationSkillNameType,
    StartAppointmentCategoryDataType,
    StartAppointmentVisibilityDataType,
    CategorySkillNameType,
    CreateUpdateAppointmentData,
} from '@/types/Appointment';

import ValidationMixin from '@/mixins/validation';
import TranslateMixin from '@/mixins/TranslateMixin';
import PermissionsMixin from '@/mixins/permissions';
import AccountMixin from '@/mixins/account';

import { duration } from '@/helpers/duration';
import { isEqual } from '@/helpers/base';

import { SubAccountType } from '@/types/Account';

const OnboardingStore = namespace('OnboardingStore');
const CommonStore = namespace('CommonStore');

@Component({
    validators: {
        appointment_id(value: string) {
            return this.baseInputValidator({ value });
        },
    },
})
export default class AddOrEditAppointmentTypeForm extends Mixins(ValidationMixin, TranslateMixin, PermissionsMixin, AccountMixin) {
    @State(state => state.OnboardingStore.categoryLocationSkillNames) categoryLocationSkillNames!: Array<CategoryLocationSkillNameType>;
    @State(state => state.OnboardingStore.appointmentCategoriesData) appointmentCategoriesData!: Array<string>;
    @State(state => state.OnboardingStore.skills) skills!: Array<CategorySkillNameType>;

    @CommonStore.Getter('subAccounts') subAccounts!: Array<SubAccountType>;
    @OnboardingStore.Action('fetchCategoryLocationSkillNames') fetchCategoryLocationSkillNames!: () => Promise<void>;
    @OnboardingStore.Action('fetchAppointmentCategoriesData') fetchAppointmentCategoriesData!: () => Promise<void>;
    @OnboardingStore.Action('fetchSkills') fetchSkills!: () => Promise<void>;

    @Prop({ type: Object, default: null }) readonly startAppointmentCategoryData!: StartAppointmentCategoryDataType;
    @Prop({ type: Boolean, default: false }) readonly showTitle!: boolean;
    @Prop({ type: Boolean, default: false }) readonly disabled!: boolean;

    appointmentCategory: string = '';
    appointmentVisibilityData: Array<StartAppointmentVisibilityDataType> = [
        {
            title: `${this.$t('SETTINGS.APPOINTMENT.TYPE.VISIBLE.TO.ALL')}`,
            key: 'visible_to_all',
        },
        {
            title: `${this.$t('SETTINGS.APPOINTMENT.TYPE.VISIBLE.WITH.LINK')}`,
            key: 'visible_with_link',
        },
    ];
    appointmentVisibility: StartAppointmentVisibilityDataType | undefined = this.appointmentVisibilityData.find(item => item.key === 'visible_to_all');
    appointment_id: string = '';
    appointment_name: string | null = '';
    appointment_sc_name: string | null = '';
    custom_order: string | null = '';
    visibility: string | null = 'saas';
    timeFrom: { duration: number, title: string } | null = null;
    skillsLoading: boolean = false;
    loading: boolean = false;
    firstCurrentForm: null | CreateUpdateAppointmentData = null;
    skillChoosed: Array<CategorySkillNameType | CategoryLocationSkillNameType> = [];
    subAccountsChoosed: Array<SubAccountType> = [];
    checkboxLocation: Array<CategoryLocationSkillNameType> = [];
    checkboxSaaS: boolean = true;
    checkboxSelfcare: boolean = false;
    duration: Array<{ duration: number, title: string }> = duration;

    serverErrors: serverErrors = {
        appointment_name: null,
    };

    get subAccountsOptions() {
        return [
            {
                type: 'All',
                accounts: this.subAccounts,
            },
        ];
    }

    get currentFormIsInvalidLocal(): boolean {
        return !(
            (this.checkboxSaaS || this.checkboxSelfcare) &&
            this.appointment_name &&
            this.timeFrom &&
            this.appointmentCategory &&
            this.checkboxLocation.length
        );
    }

    get disabledFormButton(): boolean {
        return this.currentFormIsInvalidLocal || this.isEqual;
    }

    get popupTitle(): string {
        if (!this.startAppointmentCategoryData) {
            return i18n.tc('PLAN.APPOINTMENT.ADD.HEADER');
        }
        return this.canEdit ? i18n.tc('PLAN.APPOINTMENT.EDIT.HEADER') : i18n.tc('LABEL.APPOINTMENT.TYPE');
    }

    get isEqual(): boolean {
        if (this.firstCurrentForm) {
            return isEqual(this.firstCurrentForm, this.currentForm);
        }
        return false;
    }

    isCheckBoxDisabled(item: CategoryLocationSkillNameType): boolean {
        return (
            this.disabled ||
            (
                (item.searchable_name === 'location_category_on_site' || item.searchable_name === 'location_category_on_site_in_branch') &&
                this.checkboxSelfcare && !this.isAccWithOnSiteSupport
            )
        );
    }

    get currentVisibility() {
        let visibility = 'saas';

        if (this.checkboxSelfcare && !this.checkboxSaaS) {
            visibility = 'sc';
        } else if (this.checkboxSelfcare && this.checkboxSaaS) {
            visibility = 'all';
        }

        return visibility;
    }

    get isVisibleToAll() {
        if (!this.appointmentVisibility) {
            return false;
        }

        return this.appointmentVisibility.key === 'visible_to_all';
    }

    get isAccWithOnSiteSupport(): boolean {
        return this.isPlanifAccount;
    }

    get currentForm(): CreateUpdateAppointmentData {
        return {
            title: (this.appointment_name as string),
            sc_title: (this.appointment_sc_name as string),
            custom_order: this.custom_order as string,
            kind: this.appointmentCategory,
            duration: this.timeFrom ? this.timeFrom.duration : 0,
            visibility: this.currentVisibility,
            skill_ids: [...this.skillChoosed.map(item => item.id), ...this.checkboxLocation.map(item => item.id)],
            ...this.checkboxSelfcare ? { selectable: this.isVisibleToAll } : {},
            ...this.checkboxSelfcare ? { sub_account_ids: this.subAccountsChoosed.map(item => item.id) } : {},
        };
    }

    addAppointmentData(): void {
        this.emitAddAppointmentDataHandler({
            appointment_category: this.currentForm,
        });
    }

    filterMethods(): void {
        if (this.checkboxSelfcare && !this.isAccWithOnSiteSupport) {
            this.checkboxLocation = this.checkboxLocation.filter(item => item.searchable_name !== 'location_category_on_site' &&
                item.searchable_name !== 'location_category_on_site_in_branch');
        }
    }

    @Watch('disabledFormButton')
    disabledFormButtonHandler(value: boolean): void {
        this.changeDisabledFormButtonHandler(value);
    }

    @Watch('currentForm')
    currentFormHandler(): void {
        this.addAppointmentData();
    }

    @Emit('emitAddAppointmentData')
    emitAddAppointmentDataHandler(payload: CreateAppointmentCategoryPayloadType): void {}

    @Emit('changeDisabledFormButton')
    changeDisabledFormButtonHandler(value: boolean): boolean {
        return value;
    }

    async created(): Promise<void> {
        if (!this.skills.length) {
            this.skillsLoading = true;
            await this.fetchSkills();
            this.skillsLoading = false;
        }

        if (!this.categoryLocationSkillNames.length || !this.appointmentCategoriesData.length) {
            this.loading = true;
            if (!this.categoryLocationSkillNames.length) {
                await this.fetchCategoryLocationSkillNames();
            }

            if (!this.appointmentCategoriesData.length) {
                await this.fetchAppointmentCategoriesData();
                this.appointmentCategory = '';
            }
            this.loading = false;
        }

        if (this.startAppointmentCategoryData) {
            this.checkboxLocation = this.startAppointmentCategoryData.checkboxLocation || [];
            this.skillChoosed = this.startAppointmentCategoryData.skillChoosed || [];
            this.subAccountsChoosed = this.startAppointmentCategoryData.subAccountsChoosed || [];
            this.appointmentCategory = this.startAppointmentCategoryData.appointmentCategory;
            this.timeFrom = this.startAppointmentCategoryData.timeFrom;
            this.appointment_name = this.startAppointmentCategoryData.title;
            this.appointment_sc_name = this.startAppointmentCategoryData.sc_title as string;
            this.custom_order = this.startAppointmentCategoryData.custom_order as string;
            this.appointment_id = this.startAppointmentCategoryData.id;
            this.appointmentVisibility = this.appointmentVisibilityData.find(item => item.key === this.startAppointmentCategoryData.selectable) || undefined;
            this.visibility = this.startAppointmentCategoryData.visibility as string;
            this.checkboxSaaS = this.visibility === 'saas' || this.visibility === 'all';
            this.checkboxSelfcare = this.visibility === 'sc' || this.visibility === 'all';

            this.firstCurrentForm = clone(this.currentForm);
        }
    }
}
</script>

<style lang='sass'>
.b-add-edit__form__title
    margin-bottom: 25px
    font-size: 25px
    width: 100%
    text-align: center
    font-weight: 700
</style>
