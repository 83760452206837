<template>
<div class='h-flex h-flex-dir-column b-search-fields'>
    <div class='h-width-100p h-flex'>
        <div v-if='subAccountsFromAccount && subAccountsFromAccount.length' class='h-flex-1 fw-select-base fw-select-white-modern h-mh-10'>
            <FwFormInput
                class='h-flex-1 h-mt-15'
                labelType='label-medium'
                :title='`${$t(`LABEL.SUB.ACCOUNT`)}`'>
                <FwSelect
                    v-model='subAccount'
                    class='fw-select-white'
                    :clear-on-select='false'
                    :clearable='false'
                    :allowEmpty='false'
                    :searchIcon='false'
                    :canNotDelete='true'
                    :propsPlaceholder='$t(`LABEL.SELECT.SUB.ACCOUNT`)'
                    type='white'
                    label='name'
                    :options='subAccounts'
                    :noElementsFoundText='$t(`PLAN.SOCIETY.NO.ELEMENT.FOUND`)'>
                </FwSelect>
            </FwFormInput>
        </div>
        <div class='h-flex-1 fw-select-base fw-select-white-modern h-mh-10'>
            <FwFormInput
                class='h-flex-1 h-mt-15'
                labelType='label-medium'
                :title='`${$t(`LABEL.CATEGORY`)}`'>
                <FwSelect
                    v-model='title'
                    class='fw-select-white'
                    :clear-on-select='false'
                    :canNotDelete='true'
                    :propsPlaceholder='$t(`LABEL.CATEGORY.PLACEHOLDER`)'
                    type='white'
                    :pagination='true'
                    :searchIcon='false'
                    :allowEmpty='false'
                    :options='appointmentCategories'
                    :noElementsFoundText='$t(`PLAN.SOCIETY.NO.ELEMENT.FOUND`)'>
                </FwSelect>
            </FwFormInput>
        </div>
        <div class='h-flex-1 fw-select-base fw-select-white-modern h-mh-10'>
            <FwFormInput
                class='h-mt-15'
                labelType='label-medium'
                :title='`${$t(`LABEL.TYPE`)}`'>
                <FwSelect
                    v-model='appointmentKind'
                    class='fw-select-white'
                    :clear-on-select='false'
                    :propsPlaceholder='$t(`LABEL.SELECT.TYPE`)'
                    type='white'
                    :pagination='true'
                    :searchIcon='false'
                    :allowEmpty='false'
                    :canNotDelete='true'
                    :options='appointmentKinds'
                    openDirection='bottom'
                    :noElementsFoundText='$t(`PLAN.SOCIETY.NO.ELEMENT.FOUND`)'>
                </FwSelect>
            </FwFormInput>
        </div>
        <div class='h-flex-1 fw-select-base fw-select-white-modern h-mh-10'>
            <FwFormInput
                class='h-mt-15'
                labelType='label-medium'
                :title='`${$t(`CALENDAR.METHOD.LABEL`)}`'>
                <FwSelect
                    v-model='locationMethod'
                    class='fw-select-white'
                    :clear-on-select='false'
                    :propsPlaceholder='$t(`SELFCARE.BOOKING.PLACEHOLDER.METHOD`)'
                    type='white'
                    openDirection='bottom'
                    :pagination='true'
                    :canNotDelete='true'
                    :searchIcon='false'
                    :allowEmpty='false'
                    :options='categoryLocationSkillNames'
                    :noElementsFoundText='$t(`PLAN.SOCIETY.NO.ELEMENT.FOUND`)'
                    :label='isEnLocaleExist ? labelKey : "fr_name"'>
                </FwSelect>
            </FwFormInput>
        </div>
    </div>
    <div class='h-flex h-flex-center h-flex-dir-column h-flex-justify-center h-p-20'>
        <FwButton
            :disabled='isButtonDisabled'
            borderRadiusType='small-border'
            @click='onSubmit()'>
            {{ $t('BUTTON.SEARCH') }}
        </FwButton>
        <div class='b-add-edit__form__delete h-mt-15'
             @click='resetForm'>
            {{ $t('BUTTON.CLEAR.ALL') }}
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { AppointmentCategoryType, AppointmentSearchType, CategoryLocationSkillNameType } from '@/types/Appointment';
import { SubAccountType } from '@/types/Account';

import { AppointmentWebApi } from '@/api/appointment/AppointmentApi';

import TranslateMixin from '@/mixins/TranslateMixin';

const CommonStore = namespace('CommonStore');

@Component
export default class SearchFields extends Mixins(TranslateMixin) {
    @CommonStore.Getter('subAccounts') subAccountsFromAccount!: Array<SubAccountType>;
    @Prop({ type: Object, default: null }) readonly appointmentData!: AppointmentSearchType;
    appointmentCategories: Array<String> = [];
    appointmentKinds: Array<String> = [];
    subAccounts: Array<SubAccountType> | null = null;
    subAccount: SubAccountType | null = null;
    title: string | null = '';
    appointmentKind: AppointmentCategoryType | null = null;
    locationMethod: CategoryLocationSkillNameType | null = null;
    isButtonDisabled: boolean = false;
    categoryLocationSkillNames: Array<CategoryLocationSkillNameType> = [];

    get isEnLocaleExist() {
        return this.categoryLocationSkillNames.some(item => item.en_name);
    }

    async getComponentsBuilder(): Promise<void> {
        this.isLoading = true;
        try {
            const { categories, kinds, skills, sub_accounts } = await AppointmentWebApi.getAdvisorUrlsBuilderSelectorsData({ advisor_id: this.$route.params.id });
            this.appointmentCategories = categories;
            this.appointmentKinds = kinds;
            this.subAccounts = sub_accounts;
            this.categoryLocationSkillNames = skills;
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.isLoading = false;
        }
    }

    resetForm() {
        this.appointmentKind = null;
        this.locationMethod = null;
        this.subAccount = null;
        this.title = null;
    }

    onSubmit() {
        this.$emit('getAdvisorsUrls', {
            ac_title: this.title,
            ac_kind: this.appointmentKind,
            skill_id: this.locationMethod ? this.locationMethod.id : '',
            sub_account_id: this.subAccount ? this.subAccount.id : '',
        });
    }

    created() {
        this.getComponentsBuilder();
    }
}
</script>

<style lang='sass'>
    .b-search-fields
        input::placeholder
            opacity: 0
</style>
