<template>
<div class='b-blue-background b-blue-background__opacity b-blue-background--fixed b-booking-popup__wrapper b-url-popup'>
    <template v-if='isLoading'>
        <FwSpinLoader
            :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
            class='h-flex-center loader h-width-100p h-height-100p'
            :isActive='isLoading'
            className='h-p-20'>
        </FwSpinLoader>
    </template>
    <FwPopup
        :centered='false'
        :isFixed='false'
        iconColor='#BEC7D4'
        width='auto'
        :title='""'
        :style='{
            maxHeight: `90vh`,
            maxWidth: `450px`,
            minHeight: isOnSiteFlow && isMethodStep ? `600px` : `auto`,
            position: isLoading ? `fixed` : `relative`,
            left: isLoading ? `100%` : `auto`,
            opacity: isLoading ? 0 : 1,
            visibility: isLoading ? "hidden" : "visible",
        }'
        :padding='isMobile() ? "30px 20px" : "50px"'
        class='h-flex-1 b-direct-appointment-popup qa-add-url-builder-popup'
        :allowBackgroundScrolling='true'
        :showClosePopup='true'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        @close='closeModal'>
        <template #body>
            <div v-if='urlBuilderData' style='max-width: 390px' class='h-flex-1'>
                <div class='h-text-center h-pos-rel'>
                    <h2 class='h-font-20'>
                        {{ $t("ADVISOR.YOUR.SELFCARE.LINK") }}
                    </h2>
                </div>
                <div class='h-flex h-flex-center h-mt-20'>
                    <FwIcon
                        class='h-mh-5'
                        icon='label-new'
                        size='20'
                        color='#203f6a'>
                    </FwIcon>
                    {{ currentAppointmentKind }}
                </div>
                <div class='h-flex h-flex-center h-mt-10'>
                    <FwIcon
                        class='h-mh-5'
                        icon='location-icon'
                        size='20'
                        color='#203f6a'>
                    </FwIcon>
                    {{ locationMethod[`${user().locale}_name`] }}
                </div>
                <FwFormInput
                    class='h-flex-1 h-mt-15'
                    labelType='label-medium'>
                    <FwInput
                        :value='urlBuilderData'
                        type='text'
                        name='text'
                        inputPointerEvents='none'
                        inputStyle='white-bigger-shadow'>
                        <template #right>
                            <div style='background: #fff; padding: 0 30px'>
                                <FwButton
                                    :style='{
                                        color: `#27dbbd`,
                                        textTransform: `uppercase`
                                    }'
                                    color='transparent'
                                    fontSize='14px'
                                    @click.native='copyURL'>
                                    {{ $t('LABEL.COPY') }}
                                </FwButton>
                            </div>
                        </template>
                    </FwInput>
                </FwFormInput>
            </div>
            <template v-else>
                <form class='h-mt-15'
                      @keyup.enter='submitForm'>
                    <div class='b-add-appointment-type'>
                        <div class='h-text-center h-pos-rel'>
                            <h2 class='h-font-20'>
                                {{ $tc("ADVISOR.SELFCARE.LINKS.PLURAL", 2) }}
                            </h2>
                        </div>
                        <div v-if='subAccounts && subAccounts.length' class='b-society-selection h-flex-center'>
                            <div style='width: 390px' class='fw-select-base fw-select-white-modern'>
                                <FwFormInput
                                    class='h-flex-1 h-mt-15 qa-add-url-builder-popup__sub'
                                    labelType='label-medium'
                                    :title='`${$t(`LABEL.SUB.ACCOUNT`)}`'>
                                    <FwSelect
                                        v-model='subAccount'
                                        class='fw-select-white'
                                        :clear-on-select='false'
                                        :clearable='false'
                                        :allowEmpty='false'
                                        :searchIcon='false'
                                        :canNotDelete='true'
                                        :propsPlaceholder='$t(`LABEL.SELECT.SUB.ACCOUNT`)'
                                        type='white'
                                        label='name'
                                        :options='subAccounts'
                                        :noElementsFoundText='$t(`PLAN.SOCIETY.NO.ELEMENT.FOUND`)'
                                        @input='updateAppointmentKind'>
                                    </FwSelect>
                                </FwFormInput>
                            </div>
                        </div>
                        <div class='b-society-selection h-flex-center'>
                            <div style='max-width: 390px' class='h-flex-1 fw-select-base fw-select-white-modern'>
                                <FwFormInput
                                    class='h-flex-1 h-mt-15 qa-add-url-builder-popup__category'
                                    labelType='label-medium'
                                    :title='`${$t(`LABEL.CATEGORY`)}`'>
                                    <FwSelect
                                        v-model='currentAppointmentKind'
                                        v-focus='appointmentKinds.length'
                                        class='fw-select-white'
                                        :clear-on-select='false'
                                        :canNotDelete='true'
                                        :propsPlaceholder='$t(`LABEL.CATEGORY.PLACEHOLDER`)'
                                        type='white'
                                        :disabled='subAccounts && subAccounts.length && !subAccount'
                                        :pagination='true'
                                        :searchIcon='false'
                                        :allowEmpty='false'
                                        :options='appointmentKinds'
                                        :noElementsFoundText='$t(`PLAN.SOCIETY.NO.ELEMENT.FOUND`)'
                                        @input='updateAppointmentState'
                                        @select='resetForm'>
                                    </FwSelect>
                                </FwFormInput>
                            </div>
                        </div>
                        <div class='b-society-selection h-flex-center'>
                            <div style='max-width: 390px' class='h-flex-1 fw-select-base fw-select-white-modern'>
                                <FwFormInput
                                    class='h-mt-15 qa-add-url-builder-popup__category-type'
                                    labelType='label-medium'
                                    :title='`${$t(`LABEL.TYPE`)}`'>
                                    <FwSelect
                                        :key='currentAppointmentKindState'
                                        v-model='value'
                                        class='fw-select-white'
                                        :clear-on-select='false'
                                        :propsPlaceholder='$t(`LABEL.SELECT.TYPE`)'
                                        :disabled='!currentAppointmentKind'
                                        type='white'
                                        :pagination='true'
                                        :searchIcon='false'
                                        :allowEmpty='false'
                                        :canNotDelete='true'
                                        :options='sortedAppointmentCategories'
                                        openDirection='bottom'
                                        :noElementsFoundText='$t(`PLAN.SOCIETY.NO.ELEMENT.FOUND`)'
                                        label='title'
                                        @input='fetchDataWithLocationMethod'
                                        @select='resetLocationMethod'>
                                    </FwSelect>
                                </FwFormInput>
                            </div>
                        </div>
                        <div class='b-society-selection h-flex-center'>
                            <div style='width: 390px' class='fw-select-base fw-select-white-modern'>
                                <FwFormInput
                                    class='h-flex-1 h-mt-15 qa-add-url-builder-popup__category-method'
                                    labelType='label-medium'
                                    :title='`${$t(`CALENDAR.METHOD.LABEL`)}`'>
                                    <FwSelect
                                        v-model='locationMethod'
                                        class='fw-select-white'
                                        :clear-on-select='false'
                                        :propsPlaceholder='$t(`SELFCARE.BOOKING.PLACEHOLDER.METHOD`)'
                                        :disabled='!value || isLoadingMethods'
                                        type='white'
                                        openDirection='bottom'
                                        :pagination='true'
                                        :canNotDelete='true'
                                        :searchIcon='false'
                                        :allowEmpty='false'
                                        :options='categoryLocationSkillNames'
                                        :noElementsFoundText='$t(`PLAN.SOCIETY.NO.ELEMENT.FOUND`)'
                                        :label='labelKey'>
                                    </FwSelect>
                                </FwFormInput>
                            </div>
                        </div>
                    </div>
                </form>

                <div class='h-ph-50 h-mt-40'>
                    <div class='h-flex h-flex-center'>
                        <FwButton
                            class='qa-direct-appointment__category-submit'
                            borderRadiusType='small-border'
                            :disabled='!(currentAppointmentKind && value && locationMethod && subAccount) && isRequestSending'
                            @click.native.stop.prevent='createUrl'>
                            {{ $t('BUTTON.CREATE') }}
                        </FwButton>
                    </div>
                </div>
            </template>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import { Component, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { AppointmentWebApi } from '@/api/appointment/AppointmentApi';

import {
    AppointmentCategoryType,
    CategoryLocationSkillNameType,
} from '@/types/Appointment';

import { AddNewCategoriesForm } from '@/components/forms/direct/AddNewCategoriesForm';

import DateMixin from '@/mixins/dateMixin';
import NoDataMixin from '@/mixins/no-data-mixin';
import AppointmentMixin from '@/mixins/appointment';
import ValidationMixin from '@/mixins/validation';
import TranslateMixin from '@/mixins/TranslateMixin';
import PermissionsMixin from '@/mixins/permissions';

import { BookNewAppointmentType } from '@/types/Book';
import { OnboardingApi } from '@/api/onboarding/OnboardingApi';
import { SubAccountType } from '@/types/Account';

const AppointmentStore = namespace('AppointmentStore');
const CalendarStore = namespace('CalendarStore');
const CommonStore = namespace('CommonStore');

const PRO_PREFIX = 'PRO';

@Component({
    components: {
        AddNewCategoriesForm,
    },
})
export default class CalizySelfcateAddUrlPopup extends Mixins(ValidationMixin, TranslateMixin, DateMixin, NoDataMixin, AppointmentMixin, PermissionsMixin) {
    appointmentCategories: Array<AppointmentCategoryType> = [];
    appointmentCategoriesByAccount: Array<AppointmentCategoryType> = [];
    appointmentCategory: null | AppointmentCategoryType = null;
    currentAppointmentKind: string = '';
    value: AppointmentCategoryType | null = null;
    urlBuilderData: string = '';
    isRequestSending: boolean = false;
    isLoadingMethods: boolean = false;
    isLoading: boolean = false;
    locationMethod: CategoryLocationSkillNameType | null = null;
    categoryLocationSkillNames: Array<CategoryLocationSkillNameType> = [];
    currentAppointmentKindState: string = '';
    subAccounts: Array<SubAccountType> | null = null;
    subAccount: SubAccountType | null = null;

    categoriesMappingArr = [
        'location_category_in_branch_public',
        'location_category_in_branch_private',
        'location_category_by_online_video',
        'location_category_in_branch_by_video_public',
        'location_category_in_branch_by_video_private',
        'location_category_by_phone',
        'location_category_in_branch_by_phone_public',
        'location_category_in_branch_by_phone_private',
        'location_category_on_site',
        'location_category_on_site_in_branch',
        'location_category_in_branch_client_video_private',
    ];

    get isDisable(): boolean {
        return !this.isFormValid;
    }

    get categoriesByAccount() {
        return this.subAccounts && this.subAccounts.length && this.subAccount ? this.appointmentCategoriesByAccount : this.appointmentCategories;
    }

    get sortedAppointmentCategories() {
        const categories = this.categoriesByAccount.filter(item => !this.currentAppointmentKindState || item.kind === this.currentAppointmentKindState);
        const categoriesPro = categories.filter(item => item.title.startsWith(PRO_PREFIX)).sort((a, b) => a.title.localeCompare(b.title));
        const categoriesWithOut = categories.filter(item => !item.title.startsWith(PRO_PREFIX)).sort((a, b) => a.title.localeCompare(b.title));
        return categoriesWithOut.concat(categoriesPro);
    }

    get appointmentKinds() {
        return [...new Set(this.categoriesByAccount.map(item => item.kind))].sort((a, b) => a.localeCompare(b)) || [];
    }

    closeModal() {
        this.$emit('close');
    }

    async getComponentsBuilder(): Promise<void> {
        this.isLoading = true;
        try {
            const { appointment_categories, sub_accounts } = await AppointmentWebApi.getComponentsUrlBuilder(this.$route.params.id);
            this.appointmentCategories = appointment_categories;
            this.subAccounts = sub_accounts;
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.isLoading = false;
        }
    }

    async copyURL() {
        await navigator.clipboard.writeText(this.urlBuilderData);
        this.sentNotif(`SELFCARE.YOUR.BOOKING.LINK.COPY.TO.CLIPBOARD`, true);
    }

    submitForm() {
        if (!this.isDisable) {
            this.updateAppointmentPayload();
        }
    }

    updateAppointmentPayload(): BookNewAppointmentType {
        return {
            appointment_category_id: this.value!.id,
            user_id: this.$route.params.id,
        };
    }

    updateAppointmentKind(account: SubAccountType) {
        this.currentAppointmentKind = '';
        this.value = null;
        this.locationMethod = null;
        this.appointmentCategoriesByAccount = this.appointmentCategories.filter(item => item.cat_sub_account_ids ? item.cat_sub_account_ids.includes(account.id) : item);
    }

    updateAppointmentState() {
        this.currentAppointmentKindState = this.currentAppointmentKind as string;
    }

    resetLocationMethod() {
        this.locationMethod = null;
    }

    resetForm() {
        this.value = null;
        this.locationMethod = null;
    }

    async fetchDataWithLocationMethod(value: AppointmentCategoryType) {
        this.appointmentCategory = value;

        this.isLoadingMethods = true;
        const data = await OnboardingApi.categoryLocationSkillNamesForAppointment(this.value!.id, this.$route.params.id);
        const { locations_skills_for_appointment_category } = data;

        this.isLoadingMethods = false;
        this.categoryLocationSkillNames = [];

        this.categoriesMappingArr.forEach(item => {
            locations_skills_for_appointment_category.forEach(item1 => {
                if (item1.searchable_name === item) {
                    this.categoryLocationSkillNames.push(item1);
                }
            });
        });
    }

    async createUrl() {
        try {
            this.isRequestSending = true;
            const { advisor_url } = await AppointmentWebApi.createAdvisorUrl(
                {
                    user_id: this.$route.params.id,
                    id: this.value!.id,
                    title: this.locationMethod!.searchable_name,
                    ...this.subAccounts && this.subAccounts.length ? { sub_account_id: this.subAccount!.id } : {},
                }
            );

            this.urlBuilderData = advisor_url.tiny_url;

            this.$emit('updateUrlsList');
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.isRequestSending = false;
        }
    }

    created() {
        this.getComponentsBuilder();
    }
}
</script>

<style lang="sass">
    .b-url-popup
        .multiselect__input::placeholder
            opacity: 0
        .multiselect__content-wrapper
            min-width: auto !important
            overflow-x: hidden !important
            .multiselect__option
                text-overflow: ellipsis
                white-space: nowrap
                overflow: hidden
            .multiselect__content-wrapper
                max-width: 400px !important
        .b-add-appointment-type .multiselect__content-wrapper
            max-height: 235px !important
    .b-direct-appointment-popup
        .b-appointment-left-bar_form
            .multiselect__content-wrapper
                min-width: auto !important
                overflow-x: hidden !important
                .multiselect__option
                    text-overflow: ellipsis
                    white-space: nowrap
                    overflow: hidden
            .multiselect__content-wrapper
                max-width: 400px !important
            .fw-select-address
                .multiselect__content-wrapper
                    max-width: 100% !important
        .b-separate-line
            margin: 25px 0 20px
            box-sizing: border-box
            height: 1px
            width: 200px
            border: 1px solid #213F6B
            opacity: 0.31
            transform: scaleX(-1)
        .b-info
            cursor: pointer
            &-hover
                box-shadow: 0 30px 11px -17px rgba(33, 63, 107, 0.05)
                max-width: 200px
                border-radius: 10px
                z-index: 1
                padding: 10px
                background: #fff
                position: absolute
                top: 70px
                right: 20px
                display: none
            &:hover
                .b-info-hover
                    display: block

</style>
