<template>
<div class='b-add-or-edit-branch__wrapper'>
    <form @keydown.stop.prevent.enter='onSubmit'>
        <div class='b-add-or-edit-branch__row'>
            <FwFormInput
                class='h-flex-1-0 h-ph-10'
                :fieldHasError='fieldHasError("name")'
                labelType='label-medium'
                :title='`${$t(`LABEL.NAME`)}*`'>
                <FwInput
                    class='qa-branch-name'
                    :value='name'
                    type='text'
                    name='text'
                    :placeholder='$t(`LABEL.NAME`)'
                    inputStyle='white-bigger'
                    :error='getFieldErrorText("name")'
                    @update:value='value => name = value'
                    @input='clearServerErrorsBase("name")'
                    @blur='addCurrentInputToValidateArray("name")'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='h-flex-1-0 h-ph-10'
                :fieldHasError='fieldHasError("external_id")'
                labelType='label-medium'
                :title='`${$t(`LABEL.BRANCH.ID`)}`'>
                <FwInput
                    class='qa-branch-id'
                    :value='external_id'
                    type='text'
                    name='text'
                    :placeholder='$t(`LABEL.ID`)'
                    inputStyle='white-bigger'
                    :error='getFieldErrorText("external_id")'
                    @update:value='value => external_id = value'
                    @input='clearServerErrorsBase("external_id")'
                    @blur='addCurrentInputToValidateArray("external_id")'>
                </FwInput>
            </FwFormInput>
        </div>
        <AutocompleteSelector
            v-if='!branchData'
            class='b-add-or-edit-branch__row h-ph-10'
            :startAddress='address'
            :isDisabled='branchData'
            isFullSpace
            isRequired
            @changePostCode='value => post_code = value'
            @input='input'
            @update='value => country = value'>
        </AutocompleteSelector>

        <div class='b-add-or-edit-branch__row'>
            <FwFormInput
                class='h-flex-1-0 h-ph-10'
                :fieldHasError='fieldHasError("phone")'
                labelType='label-medium'
                :title='`${$t(`LABEL.PHONE.NUMBER`)}`'>
                <FwInput
                    :value='phone'
                    type='number'
                    name='text'
                    :placeholder='$t(`LABEL.PHONE.NUMBER`)'
                    inputStyle='white-bigger'
                    :error='getFieldErrorText("phone")'
                    :propsKeyDownMethod='propsKeyDownMethodOnlyNumbers'
                    @update:value='value => phone = value'
                    @input='clearServerErrorsBase("phone")'
                    @blur='addCurrentInputToValidateArray("phone")'>
                </FwInput>
            </FwFormInput>

            <FwFormInput
                class='h-flex-0-0-50p h-ph-10'
                :fieldHasError='fieldHasError("email")'
                labelType='label-medium'
                :title='`${$t(`LABEL.EMAIL`)}`'>
                <FwInput
                    :value='email'
                    type='text'
                    name='text'
                    :placeholder='$t(`LABEL.EMAIL`)'
                    inputStyle='white-bigger'
                    :disabled='disabledForm'
                    :error='getFieldErrorText("email")'
                    @update:value='value => email = value'
                    @input='clearServerErrorsBase("email")'
                    @blur='addCurrentInputToValidateArray("email")'>
                </FwInput>
            </FwFormInput>
        </div>
    </form>
    <div v-if='!isEdit' class='h-flex h-flex-center h-flex-space-between'>
        <h2>
            {{ $t('ADD.ROOM.QUESTION') }}
        </h2>
        <FwLabelCard
            class='b-add-edit__form--label-card'>
            <div class='h-flex h-flex-center h-flex-space-between h-height-100p'>
                <div class='h-flex h-flex-center'>
                    <span class='b-add-worker__is_admin_active'>
                        {{ isActive ? $t('WORKER.FORM.ADMIN.YES') : $t('WORKER.FORM.ADMIN.NO') }}
                    </span>
                </div>
                <FwSwitcher
                    class='h-mh-10'
                    :isActive='isActive'
                    @toggleSwitch='toggleActive'>
                </FwSwitcher>
            </div>
        </FwLabelCard>
    </div>
    <div class='b-form-hint__wrapper'>
        <div class='h-mt-10 h-fw-500 h-font-13'>
            * {{ $t(`LABEL.REQUIRED.FIELD`) }}
        </div>
    </div>

    <div v-if='isEdit' class='h-flex h-flex-center h-flex-dir-column'>
        <FwButton
            class='h-mt-20'
            :disabled='isDisabled || isRequestSending'
            borderRadiusType='small-border'
            @click='onSubmit'>
            {{ $t('BUTTON.SAVE') }}
        </FwButton>
        <div v-if='canDelete'
             class='b-add-branch__form__delete qa-branch-form-delete-button'
             @click='$emit("delete")'>
            {{ $t('PLAN.BRANCH.DELETE.TXT') }}
        </div>
    </div>

    <div v-else class='h-flex h-flex-center'>
        <FwButton
            class='h-mt-30 qa-branch-form-submit'
            :disabled='isDisabled || isRequestSending || isGeoScopeWithoutCountry'
            borderRadiusType='small-border'
            @click='onSubmit'>
            {{ $t('BUTTON.SAVE') }}
        </FwButton>
    </div>
</div>
</template>

<script lang='ts'>
import { clone } from 'ramda';
import { Component, Mixins, Watch, Emit, Prop } from 'vue-property-decorator';

import { BranchSitesDataType } from '@/types/Site';
import { ParsedAddressData } from '@/types/GoogleMap';
import { BranchFormDataPayloadType } from '@/types/Branch';
import { Country } from '@/types/Account';

import ValidationMixin from '@/mixins/validation';
import PermissionsMixin from '@/mixins/permissions';

import { AutocompleteSelector } from '@/components/nodes/AutocompleteSelector';

import { addPostCodeToAddress } from '@/helpers/address';
import { isEqual } from '@/helpers/base';

@Component({
    components: {
        AutocompleteSelector,
    },
    validators: {
        name(value: string) {
            return this.baseInputValidator({ value });
        },
        address(value: string) {
            return this.baseInputValidator({ value });
        },
        email(value: string) {
            return this.emailValidator({ value, notRequired: true });
        },
    },
})
export default class AddOrEditBranchForm extends Mixins(ValidationMixin, PermissionsMixin) {
    @Prop({ type: Object, default: null }) readonly branchData!: BranchSitesDataType;
    @Prop({ type: String, default: null }) readonly errorFromServer!: string;
    @Prop({ type: Boolean, default: false }) readonly isRequestSending!: boolean;

    isEdit: boolean = false;
    isActive: boolean = false;
    name: string = '';
    address: string = '';
    phone: string = '';
    external_id: string = '';
    email: string = '';
    post_code: string = '';
    countryString: string = '';
    country: Country | null | undefined = null;
    latitude: number | null = null;
    longitude: number | null = null;

    startFormData: BranchFormDataPayloadType | null = null;
    serverErrors: serverErrors = {
        name: null,
        address: null,
        external_id: null,
        email: null,
    };

    get isDisabled(): boolean {
        if (this.isEdit) {
            return isEqual(this.startFormData, this.currentForm) || !this.name;
        }
        return !this.name || !this.address;
    }

    get isGeoScopeWithoutCountry() {
        return this.isGeoScopeInternationalWithCountries && !this.country;
    }

    toggleActive(): void {
        this.isActive = !this.isActive;
        this.$emit('toggleRoomButton', this.isActive);
    }

    input(parsedAddressData: ParsedAddressData): void {
        this.post_code = parsedAddressData.post_code;
        this.address = parsedAddressData.calizyAddress;
        this.countryString = parsedAddressData.country;
        if (parsedAddressData.latitude && parsedAddressData.longitude) {
            this.latitude = parsedAddressData.latitude as number;
            this.longitude = parsedAddressData.longitude as number;
        }
    }

    updateFormByServerValues(): void {
        if (this.branchData) {
            if (this.branchData.name) {
                this.name = this.branchData.name;
            }
            if (this.branchData.address) {
                this.address = this.branchData.address;
            }
            if (this.branchData.email) {
                this.email = this.branchData.email.trim();
            }
            if (this.branchData.phone) {
                this.phone = this.branchData.phone;
            }
            if (this.branchData.external_id) {
                this.external_id = this.branchData.external_id;
            }
            if (this.branchData.post_code) {
                this.post_code = this.branchData.post_code;
            }
        }
    }

    get currentForm(): BranchFormDataPayloadType {
        let payload = {};
        if (this.country && this.country.country_iso) {
            payload = {
                ...payload,
                country_iso: this.country.country_iso,
            };
        }
        if (this.latitude && this.longitude) {
            payload = {
                ...payload,
                latitude: this.latitude,
                longitude: this.longitude,
            };
        }
        return {
            name: this.name,
            address: this.isGeoScopeInternationalWithCountries ? this.address : addPostCodeToAddress(this.address, this.post_code, this.countryString),
            phone: this.phone,
            external_id: this.external_id,
            email: this.email,
            kind: 'branch',
            post_code: this.post_code,
            isActive: this.isActive,
            ...payload,
        };
    }

    created(): void {
        if (this.branchData) {
            this.isEdit = true;
            this.updateFormByServerValues();
            this.startFormData = clone(this.currentForm);
        }
    }

    mounted(): void {
        if (this.isEdit) {
            this.addCurrentInputToValidateArray('name');
        }
    }

    @Watch('errorFromServer')
    checkEmailError(): void {
        if (this.errorFromServer.includes('Email')) {
            this.serverErrors = {
                // @ts-ignore-next-line
                email: this.$i18n.t('COMPANY.EMAIL.TAKEN'),
            };
        }
    }

    @Emit('onSubmit')
    onSubmit(): BranchFormDataPayloadType {
        return this.currentForm;
    }
}
</script>

<style lang='sass'>
.b-add-or-edit-branch
    &__wrapper
        padding: 0 40px
        margin-top: 30px

        @include media('<=phone')
            padding: 0
            margin-top: 20px
    &__row
        @include media('>phone')
            display: flex
            justify-content: space-between
            margin-bottom: 30px

        @include media('<=phone')
            .fw-form-input__wrapper
                margin-bottom: 12px

.b-add-branch__form
    display: flex
    flex-wrap: wrap
    margin: 0 -15px

    &__row
        display: flex
        width: 100%
        margin-bottom: 0

        @include media('<=phone')
            flex-direction: column
            padding: 0 15px
            margin-bottom: 0 !important

            .b-add-branch__form__input
                margin: 8px 15px

    &__input
        flex: 1 0
        margin: 0 15px
        input
            &:disabled
                color: darkgray

    &__delete
        text-transform: uppercase
        margin-top: 20px
        font-size: 12px
        color: #FF004D
        font-weight: bold
        cursor: pointer
        text-align: center

        &:hover
            opacity: 0.7

.b-select-address__outer
    .multiselect__content-wrapper
        position: absolute !important
        margin-top: 70px !important
        background-color: #fff !important
</style>
