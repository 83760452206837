import { ParsedAddressData } from '@/types/GoogleMap';
import { AddressFrResponseType, AddressResponseType } from '@/types/Autocomplete';

function parseAutocompleteWithApiAsAddress(place: AddressFrResponseType | AddressResponseType): ParsedAddressData {
    const country = place.country as string;
    const address = place.label;
    const latitude = place.latitude as number;
    const longitude = place.longitude as number;
    return {
        calizyAddress: address,
        post_code: place.post_code,
        address,
        country,
        latitude,
        longitude,
    };
}

export {
    parseAutocompleteWithApiAsAddress,
};
