<template>
<div class='b-blue-background b-blue-background__opacity b-blue-background--fixed'>
    <ErrorPopup
        v-if='showErrorPopup && !finalePopupValue'
        :statusCode='errorStatus'
        :statusText='errorStatusText'
        :text='errorText'
        :buttonText='$t("FINISH.POPUP.SUCCESS.CLOSE")'
        @close='showErrorPopup = false'>
    </ErrorPopup>
    <FinalePopup
        v-if='finalePopupValue && !showErrorPopup'
        :popupWidth='`500px`'
        :headerText='finalePopupTitle'
        @close='closeFinalePopup'>
        <template #subButtons>
            <div class='h-flex-center'>
                <FwButton
                    class='h-mt-20 qa-consult-recap__appointment-page'
                    :style='{
                        color: `#27dbbd`,
                        textTransform: `uppercase`
                    }'
                    color='transparent'
                    fontSize='12px'
                    @click.native='moveToConsultRecap'>
                    {{ $t('BUTTON.CONSULT.RECAP') }}
                </FwButton>
            </div>
        </template>
    </FinalePopup>
    <FwPopup
        v-else
        v-show='!isUpdateNowWithCurrentAdvisor'
        class='h-pos-rel'
        style='max-height: 90%;overflow-y: auto; justify-content: flex-start'
        :centered='false'
        :isFixed='false'
        iconColor='#BEC7D4'
        max-width='900px'
        width='auto'
        title=''
        :padding='isMobile() ? "20px 20px 0" : "30px 40px 0"'
        :allowBackgroundScrolling='true'
        :showClosePopup='true'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        heightValue='100%'
        :disableClosingOnClickOutside='true'
        @close='$emit(`close`)'>
        <template #body>
            <div v-if='appointmentCategory'
                 class='h-text-center'>
                <h1 class='h-font-22'>
                    {{ appointmentCategory.title }}
                </h1>
            </div>
            <div class='h-flex-center b-book-appointment__popup'>
                <BookingForm
                    formClass='b-cancel-appointment__form'
                    iconsWrapperClass='b-cancel-appointment__form__events'
                    :date='date'
                    :dates='dates'
                    :client='client'
                    :eventData='event'
                    :post_code='post_code'
                    :country_iso='country_iso'
                    :selectedAdvisor='selectedAdvisor'
                    :start_phone_number='phone_number'
                    :start_email='email'
                    :branchAddress='branchAddress'
                    :isRequestSending='isRequestSending'
                    :canUpdateDisabledFields='canUpdateDisabledFields'
                    :placeParsedData='placeParsedData'
                    :isUpdateNowWithCurrentAdvisor='isUpdateNowWithCurrentAdvisor'
                    @onSubmit='onSubmit'
                    @close='$emit(`close`)'>
                </BookingForm>
            </div>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import { namespace } from 'vuex-class';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { dateAsEventTime, getHoursAndMinutesFromAMPM, dateToString } from '@/helpers/dates';
import DateMixin from '@/mixins/dateMixin';
import { ErrorPopup } from '@/components/popups/ErrorPopup';
import { FinalePopup } from '@/components/popups/FinalePopup';
import { BookingEventType } from '@/types/Availabilities';
import { BookingForm } from '@/components/forms/BookingForm';
import { BookApi } from '@/api/book/BookApi';
import NoDataMixin from '@/mixins/no-data-mixin';
import { ClientType, UserListType } from '@/types/User';
import { FormBookAppointmentType } from '@/types/Book';
import AccountMixin from '@/mixins/account';
import {
    AppointmentCategoryType,
    BranchAddressType,
    AppointmentOnSiteDataType,
    CategoryLocationSkillNameType,
    AppointmentSearchType,
} from '@/types/Appointment';
import CalendarMixin from '@/mixins/calendar';
import { ParsedAddressData } from '@/types/GoogleMap';
import { AppointmentWebApi } from '@/api/appointment/AppointmentApi';

const AppointmentStore = namespace('AppointmentStore');

@Component({
    components: {
        BookingForm,
        ErrorPopup,
        FinalePopup,
    },
})
export default class BookAppointmentPopup extends Mixins(DateMixin, CalendarMixin, NoDataMixin, AccountMixin) {
    @AppointmentStore.State('appointmentDataToUpdate') appointmentDataToUpdate!: AppointmentSearchType | null;
    @AppointmentStore.State('appointmentOnSiteData') appointmentOnSiteData!: AppointmentOnSiteDataType | null;
    @AppointmentStore.State('opportunityId') opportunityId!: string;
    @AppointmentStore.State('caseId') caseId!: string;
    @AppointmentStore.Mutation('setAppointmentDataToUpdate') setAppointmentDataToUpdate!: (type: AppointmentSearchType | null) => void;
    @AppointmentStore.State('placeParsedData') placeParsedData!: ParsedAddressData | null;
    @AppointmentStore.State('locationName') locationName!: CategoryLocationSkillNameType;

    @Prop({ type: Object, required: true }) readonly event!: BookingEventType;
    @Prop({ type: Object, default: null }) readonly client!: ClientType;
    @Prop({ type: Object, required: true }) readonly appointmentCategory!: AppointmentCategoryType;
    @Prop({ type: Date, required: true }) readonly date!: Date;
    @Prop({ type: Object, default: null }) readonly branchAddress!: BranchAddressType;
    @Prop({ type: String, default: null }) readonly post_code!: string;
    @Prop({ type: String, default: null }) readonly country_iso!: string;
    @Prop({ type: Boolean, default: false }) readonly canUpdateDisabledFields!: boolean;
    @Prop({ type: Boolean, default: false }) readonly isUpdateNowWithCurrentAdvisor!: boolean;
    @Prop({ type: String, default: null }) readonly currentRoomId!: string | null;
    @Prop({ type: String, default: null }) readonly branchSiteId!: string;
    @Prop({ type: String, default: '' }) readonly email!: string;
    @Prop({ type: String, default: '' }) readonly phone_number!: string;
    @Prop({ type: Object, required: true }) readonly selectedAdvisor!: UserListType | null;

    isRequestSending: boolean = false;
    errorStatus: string = '';
    errorStatusText: string = '';
    errorText: string = '';
    finalePopupValue: boolean = false;
    showErrorPopup: boolean = false;
    bookedAppointment: null | AppointmentOnSiteDataType = null;

    get eventTime(): string {
        return dateAsEventTime(new Date(this.event.slot), this.user);
    }
    get timeEnd() {
        return getHoursAndMinutesFromAMPM(this.dates[1]);
    }

    get validSiteId() {
        return this.currentRoomId || this.branchAddress ?
            { site_id: this.currentRoomId || this.branchAddress.id } :
            {};
    }

    get dtEnd() {
        const dtEndMillis = new Date(this.event.slot).setHours(this.timeEnd.hours, this.timeEnd.minutes);
        return dateToString(new Date(dtEndMillis));
    }

    get finalePopupTitle() {
        return this.$t(this.appointmentDataToUpdate ? 'APPOINTMENT.UPDATED.SUCCESS.TEXT' : 'APPOINTMENT.SUCCESS.TEXT');
    }

    get dates(): Array<string> {
        const eventDate: Date = new Date(this.event.slot);
        const finishDate: Date = new Date(eventDate.getTime());
        const minutes = this.appointmentCategory.duration % 60;
        const hours = this.appointmentCategory.duration / 60;
        finishDate.setHours(finishDate.getHours() + hours, finishDate.getMinutes() + minutes);
        return [
            this.getCurrentDateAsMinutesAMPM(eventDate),
            this.getCurrentDateAsMinutesAMPM(finishDate),
        ];
    }

    closeFinalePopup() {
        this.finalePopupValue = false;
        this.$emit('close');
        this.setAppointmentDataToUpdate(null);
        this.$router.push({ path: '/consult/choose' });
    }

    async onSubmit(payload: FormBookAppointmentType) {
        this.isRequestSending = true;
        try {
            if (this.appointmentDataToUpdate) {
                try {
                    let location = {};
                    if (this.placeParsedData && this.isBookCalendarOnSite) {
                        const durationTo = parseInt((this.event.duration_to_in_min_text as string).replace(/\D/g, ''), 10);
                        const durationFrom = parseInt((this.event.duration_from_in_min_text as string).replace(/\D/g, ''), 10);
                        location = {
                            location: this.placeParsedData!.calizyAddress,
                        };
                        payload = {
                            ...payload,
                            post_code: this.placeParsedData!.post_code,
                            drive_duration_from: durationFrom,
                            drive_duration_to: durationTo,
                        };
                    } else if (this.appointmentDataToUpdate) {
                        location = {
                            ...this.appointmentDataToUpdate!.location ? { location: this.appointmentDataToUpdate!.location } : {},
                        };
                    }
                    // @ts-ignore
                    this.bookedAppointment = await AppointmentWebApi.updateAppointmentData(
                        {
                            appointment_category_id: this.appointmentCategory.id,
                            dt_start: this.event.slot,
                            dt_end: this.dtEnd,
                            category: this.locationName ? this.locationName.searchable_name : this.appointmentDataToUpdate!.category,
                            user_id: this.event.technician_id,
                            ...this.branchSiteId ? { site_id: this.branchSiteId } : {},
                            ...location,
                            ...payload,
                        }, this.appointmentDataToUpdate!.id as string);
                    this.finalePopupValue = true;
                } catch ({ response }) {
                    if (this.isUpdateNowWithCurrentAdvisor) {
                        this.$emit('close', true);
                    }
                    this.sentNotif(response);
                }
            } else {
                const asideParams = {
                    ...(this.client && this.client.id && { client_id: this.client.id }),
                    ...(this.opportunityId && { opportunity_id: this.opportunityId }),
                    ...(this.caseId && { case_id: this.caseId }),
                };
                if (this.isBookCalendarOnPhone) {
                    try {
                        this.bookedAppointment = await BookApi.bookAppointmentTelecom(
                            {
                                appointment_category_id: this.appointmentCategory.id,
                                dt_start: this.event.slot,
                                advisor_id: this.event.technician_id,
                                account_id: this.user().account_id,
                                category: this.locationName.searchable_name,
                                ...asideParams,
                                ...payload,
                            });
                        this.finalePopupValue = true;
                    } catch ({ response }) {
                        this.sentNotif(response);
                    }
                } else if (this.isBookCalendarByOnlineVideo) {
                    try {
                        this.bookedAppointment = await BookApi.bookAppointmentTelecom(
                            {
                                appointment_category_id: this.appointmentCategory.id,
                                dt_start: this.event.slot,
                                advisor_id: this.event.technician_id,
                                account_id: this.user().account_id,
                                category: this.locationName.searchable_name,
                                ...asideParams,
                                ...payload,
                            });
                        this.finalePopupValue = true;
                    } catch ({ response }) {
                        this.sentNotif(response);
                    }
                } else if (this.isBookCalendarInBranchFaceToFace) {
                    try {
                        this.bookedAppointment = await BookApi.bookAppointmentBranchPublic(
                            {
                                appointment_category_id: this.appointmentCategory.id,
                                dt_start: this.event.slot,
                                advisor_id: this.event.technician_id,
                                account_id: this.user().account_id,
                                site_id: this.branchAddress.id,
                                category: this.locationName.searchable_name,
                                ...asideParams,
                                ...payload,
                            });
                        this.finalePopupValue = true;
                    } catch ({ response }) {
                        this.sentNotif(response);
                    }
                } else if (this.isBookCalendarInServicePoints) {
                    try {
                        this.bookedAppointment = await BookApi.bookInServicePoint(
                            {
                                appointment_category_id: this.appointmentCategory.id,
                                dt_start: this.event.slot,
                                advisor_id: this.event.technician_id,
                                account_id: this.user().account_id,
                                site_id: this.branchAddress.id,
                                category: this.locationName.searchable_name,
                                ...asideParams,
                                ...payload,
                            });
                        this.finalePopupValue = true;
                    } catch ({ response }) {
                        this.sentNotif(response);
                    }
                } else if (this.isBookCalendarBranchPrivateVideo) {
                    try {
                        this.bookedAppointment = await BookApi.bookAppointmentBranchPrivate(
                            {
                                dt_start: this.event.slot,
                                site_id: (this.currentRoomId as string),
                                advisor_id: this.event.technician_id,
                                appointment_category_id: this.appointmentCategory.id,
                                category: this.locationName.searchable_name,
                                ...asideParams,
                                ...payload,
                            }
                        );
                        this.finalePopupValue = true;
                    } catch ({ response }) {
                        this.sentNotif(response);
                    }
                } else if (this.isBookCalendarOnSite) {
                    if (!this.appointmentOnSiteData) {
                        this.$_NoDataMixin_bookFlowNoData();
                        return;
                    }
                    try {
                        const durationTo = parseInt((this.event.duration_to_in_min_text as string).replace(/\D/g, ''), 10);
                        const durationFrom = parseInt((this.event.duration_from_in_min_text as string).replace(/\D/g, ''), 10);

                        this.bookedAppointment = await BookApi.bookAppointmentOnSite(
                            {
                                dt_start: this.event.slot,
                                user_id: this.event.technician_id,
                                drive_duration_from: durationFrom,
                                drive_duration_to: durationTo,
                                category: this.locationName.searchable_name,
                                ...asideParams,
                                ...payload,
                            }, this.appointmentOnSiteData!.id
                        );
                        this.finalePopupValue = true;
                    } catch ({ response }) {
                        this.sentNotif(response);
                    }
                } else if (this.isBookCalendarOnSiteInBranch) {
                    if (!this.appointmentOnSiteData) {
                        this.$_NoDataMixin_bookFlowNoData();
                        return;
                    }
                    try {
                        const durationTo = parseInt((this.event.duration_to_in_min_text as string).replace(/\D/g, ''), 10);
                        const durationFrom = parseInt((this.event.duration_from_in_min_text as string).replace(/\D/g, ''), 10);

                        this.bookedAppointment = await BookApi.bookAppointmentOnSiteInBranch(
                            {
                                dt_start: this.event.slot,
                                user_id: this.event.technician_id,
                                drive_duration_from: durationFrom,
                                drive_duration_to: durationTo,
                                category: this.locationName.searchable_name,
                                ...asideParams,
                                ...payload,
                            }, this.appointmentOnSiteData.id
                        );
                        this.finalePopupValue = true;
                    } catch ({ response }) {
                        this.sentNotif(response);
                    }
                } else if (this.isBookCalendarInBranchVideoconference) {
                    try {
                        this.bookedAppointment = await BookApi.bookAppointmentBranchPrivate(
                            {
                                dt_start: this.event.slot,
                                site_id: (this.currentRoomId as string),
                                advisor_id: this.event.technician_id,
                                appointment_category_id: this.appointmentCategory.id,
                                category: this.locationName.searchable_name,
                                ...asideParams,
                                ...payload,
                            }
                        );
                        this.finalePopupValue = true;
                    } catch ({ response }) {
                        this.sentNotif(response);
                    }
                } else if (this.isBookCalendarBranchByPhonePublic) {
                    try {
                        this.bookedAppointment = await BookApi.bookAppointmentBranchPublic(
                            {
                                appointment_category_id: this.appointmentCategory.id,
                                dt_start: this.event.slot,
                                advisor_id: this.event.technician_id,
                                account_id: this.user().account_id,
                                site_id: this.branchAddress.id,
                                category: this.locationName.searchable_name,
                                ...asideParams,
                                ...payload,
                            });
                        this.finalePopupValue = true;
                    } catch ({ response }) {
                        this.sentNotif(response);
                    }
                } else if (this.isBookCalendarBranchByVideoPublic) {
                    try {
                        this.bookedAppointment = await BookApi.bookAppointmentBranchPublic(
                            {
                                appointment_category_id: this.appointmentCategory.id,
                                dt_start: this.event.slot,
                                advisor_id: this.event.technician_id,
                                account_id: this.user().account_id,
                                site_id: this.branchAddress.id,
                                category: this.locationName.searchable_name,
                                ...asideParams,
                                ...payload,
                            }
                        );
                        this.finalePopupValue = true;
                    } catch ({ response }) {
                        this.sentNotif(response);
                    }
                } else if (this.isBookCalendarBranchByPhonePrivate) {
                    try {
                        this.bookedAppointment = await BookApi.bookAppointmentBranchPrivate(
                            {
                                dt_start: this.event.slot,
                                site_id: (this.currentRoomId as string),
                                advisor_id: this.event.technician_id,
                                appointment_category_id: this.appointmentCategory.id,
                                category: this.locationName.searchable_name,
                                ...asideParams,
                                ...payload,
                            }
                        );
                        this.finalePopupValue = true;
                    } catch ({ response }) {
                        this.sentNotif(response);
                    }
                } else if (this.isBookCalendarBranchByVideoPrivate) {
                    try {
                        this.bookedAppointment = await BookApi.bookAppointmentBranchPrivate(
                            {
                                dt_start: this.event.slot,
                                site_id: (this.currentRoomId as string),
                                advisor_id: this.event.technician_id,
                                appointment_category_id: this.appointmentCategory.id,
                                category: this.locationName.searchable_name,
                                ...asideParams,
                                ...payload,
                            }
                        );
                        this.finalePopupValue = true;
                    } catch ({ response }) {
                        this.sentNotif(response);
                    }
                }
            }
        } catch ({ response }) {
            this.showErrorPopup = true;
            this.errorText = response.data.errors[0];
            this.errorStatus = response.status;
            this.errorStatusText = response.statusText;
            this.serverErrors = Object.assign(this.serverErrors, this.prepareServerFormErrors(response.data));
        } finally {
            this.isRequestSending = false;
        }
    }

    moveToConsultRecap() {
        if (this.bookedAppointment) {
            this.$router.push({ path: `/consult/choose/appointments`, query: { id: this.bookedAppointment.id }});
        }
    }
}
</script>

<style lang='sass'>
.b-book-appointment
    &__popup
        .b-cancel-appointment__form__events
            margin-bottom: 20px

.fw-button--transparent
    background-color: transparent !important

.b-cancel-appointment__form
    .b-form-base-line
        padding-bottom: 20px
        @include media('<=phone')
            padding-bottom: 0
</style>
