<template>
<div>
    <GoBackButton
        style='top: 40px; left: 40px'
        class='b-route-wrapper__back_arrow'
        :method='backToCategoriesStep'
        @click.native.stop.prevent>
    </GoBackButton>
    <form
        class='h-flex h-flex-dir-column h-flex-center'
        :class='formClass'
        @keyup.enter='toBookingForm'>
        <div v-if='isOnSiteFlow'
             class='b-add-appointment-type h-width-100p'>
            <div class='h-text-center'>
                <h2 class='h-font-20'>
                    {{ $t('SELECT.TYPE.APPOINTMENT.CATEGORY.TITLE') }}
                </h2>
            </div>
            <AutocompleteSelector
                :startAddress='address'
                isAutoFocus
                isFullSpace
                isDirectionColumn
                @input='input'
                @update='value => country = value'>
            </AutocompleteSelector>
        </div>
        <template v-else>
            <div class='h-text-center'>
                <h2 class='h-font-20'>
                    {{ $t('SELECT.POSTCODE.APPOINTMENT.TITLE') }}
                </h2>
            </div>
            <div v-if='isGeoScopeInternationalWithCountries' class='h-flex h-flex-center'>
                <div style='width: 330px' class='fw-select-base h-mr-20'>
                    <FwFormInput
                        labelType='label-medium'
                        :title='`${$t(`LABEL.COUNTRY`)}`'>
                        <FwSelect
                            v-model='country'
                            class='fw-select-white qa-select-appointment-skill'
                            :propsPlaceholder='$t(`LABEL.SELECT.COUNTRY`)'
                            type='white'
                            label='name'
                            :searchable='false'
                            :canNotDelete='true'
                            :options='geoScopeCountries'>
                        </FwSelect>
                    </FwFormInput>
                </div>
                <div style='width: 330px' class='fw-select-base'>
                    <FwFormInput
                        class='qa-direct-appointment__location-post-code'
                        labelType='label-medium'
                        :title='$t("SELECT.POSTCODE.APPOINTMENT.PLACEHOLDER")'>
                        <FwInput
                            v-focus='true'
                            :value='local_post_code'
                            type='number'
                            name='text'
                            inputStyle='white-bigger'
                            :propsKeyDownMethod='propsKeyDownMethodOnlyNumbers'
                            placeholder='ex: 75001'
                            @update:value='value => local_post_code = value'
                            @blur='addCurrentInputToValidateArray("code")'>
                        </FwInput>
                    </FwFormInput>
                </div>
            </div>
        </template>
    </form>

    <div class='h-ph-50 h-mt-40'>
        <div class='h-flex h-flex-center'>
            <FwButton
                class='qa-direct-appointment__location-submit'
                :class='{
                    "h-pos-abs h-bottom-50": isOnSiteFlow
                }'
                borderRadiusType='small-border'
                :disabled='isDisabled'
                @click.native.stop.prevent='toBookingForm'>
                {{ $t('BUTTON.NEXT') }}
            </FwButton>
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { GoBackButton } from '@/components/simple/GoBackButton';
import { AutocompleteSelector } from '@/components/nodes/AutocompleteSelector';

import {
    AppointmentCategoryType,
} from '@/types/Appointment';
import { ParsedAddressData } from '@/types/GoogleMap';
import { Country } from '@/types/Account';

import ValidationMixin from '@/mixins/validation';
import TranslateMixin from '@/mixins/TranslateMixin';
import AppointmentMixin from '@/mixins/appointment';
import PermissionsMixin from '@/mixins/permissions';

import {
    BOOKING_ADDRESS,
    BOOKING_POST_CODE,
} from '@/helpers/booking';

const AppointmentStore = namespace('AppointmentStore');
const CalendarStore = namespace('CalendarStore');

@Component({
    validators: {
        [BOOKING_ADDRESS](value: string) {
            return this.baseInputValidator({ value, isRequired: Boolean(this.isBookCalendarInBranchVideoconference || this.isBookCalendarInBranchFaceToFace) });
        },
    },
    components: {
        AutocompleteSelector,
        GoBackButton,
    },
})
export default class LocationMethodPage extends Mixins(ValidationMixin, TranslateMixin, AppointmentMixin, PermissionsMixin) {
    @AppointmentStore.State('appointmentAddress') appointmentAddress!: string;
    @AppointmentStore.Mutation('setPlaceParsedData') setPlaceParsedData!: (address: ParsedAddressData | null) => void;
    @AppointmentStore.State('post_code') post_code!: string;
    @AppointmentStore.Mutation('setAppointmentAddress') setAppointmentAddress!: (address: string) => void;
    @AppointmentStore.Mutation('setPostCode') setPostCode!: (post_code: string) => void;
    @CalendarStore.Mutation('setCountryIso') setCountryIso!: (country_iso: string) => void;

    @Prop({ type: String, default: null }) readonly formClass!: string;

    value: AppointmentCategoryType | null = null;
    addressData: null | ParsedAddressData = null;
    [BOOKING_ADDRESS]: string = '';
    [BOOKING_POST_CODE]: string = '';
    BOOKING_ADDRESS: string = BOOKING_ADDRESS;
    BOOKING_POST_CODE: string = BOOKING_POST_CODE;
    country: Country | null | undefined = null;
    latitude: number | null = null;
    longitude: number | null = null;

    created() {
        if (this.appointmentAddress) {
            this[BOOKING_ADDRESS] = this.appointmentAddress;
        }
        if (this.post_code) {
            this[BOOKING_POST_CODE] = this.post_code;
        }
    }

    get isDisabled() {
        return !this[BOOKING_ADDRESS] && !this[BOOKING_POST_CODE] || (this.isGeoScopeInternationalWithCountries && !this.country);
    }

    input(parsedAddressData: ParsedAddressData) {
        let data = parsedAddressData;
        if (this.geoScopeInternational && this.country) {
            data = { ...parsedAddressData, ...{ countryData: this.country }};
        }
        this.addressData = data;
        this[BOOKING_POST_CODE] = data.post_code;
        this[BOOKING_ADDRESS] = data.calizyAddress;
        this.addCurrentInputToValidateArray(BOOKING_ADDRESS);
    }

    @Emit('backToCategoriesStep')
    backToCategoriesStep() {}

    @Emit('toBookingForm')
    toBookingForm() {
        this.setPostCode(this[BOOKING_POST_CODE]);
        this.setAppointmentAddress(this[BOOKING_ADDRESS]);
        this.setPlaceParsedData(this.addressData);
        if (this.country && this.country.country_iso) {
            this.setCountryIso(this.country.country_iso);
        }
    }

    @Watch('country')
    updateValue() {
        this[BOOKING_POST_CODE] = '';
        this[BOOKING_ADDRESS] = '';
    }
}
</script>

<style lang='sass'>
.b-appointment-left-bar_form
    .fw-input--white, .fw-input--textarea
        box-shadow: none
        &::placeholder
            color: #909FB5
    .fw-input__error-text
        margin: 3px 0 15px
        font-size: 12px
        color: #FF004D
    .b-cancel-appointment__form
        .multiselect__tags
            min-height: 60px !important
            padding-left: 15px !important
            border-radius: 10px !important

    .fw-check-box__wrapper.with-text
        display: flex
        flex-direction: row
        width: 100%
        justify-content: flex-start
        margin-top: 10px
        .fw-check-box__label
            margin-left: 10px
            font-weight: 400

    .fw-select-white
        box-shadow: none !important

    .multiselect__input
        opacity: 1

    input.multiselect__input
        padding: 0 !important

    &__head
        height: 35px

.b-base-form-margin
    margin-bottom: 35px
    @include media('<tablet')
        margin-bottom: 10px

.h-align-items-end
    align-items: end

.b-booking-popup__wrapper
    .fw-popup
        overflow: auto
        display: block
</style>
