import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { BookingEventType, ConsultEventType, SetAvailabilitiesToStoreType } from '@/types/Availabilities';
import { removeDuplicates } from '@/helpers/base';
import { UserListType } from '@/types/User';
import { CalendarTechniciansType, CalendarViewSettingsType } from '@/types/Calendar';
import { ServicePointShiftListType } from '@/types/Events';

@Module({ namespaced: true })
class CalendarStore extends VuexModule {
    post_code_availabilities: Array<BookingEventType | ConsultEventType | ServicePointShiftListType> = [];
    post_code: string = '';
    country_iso: string = '';
    usersList: Array<UserListType> = [];
    calendar_view_data: CalendarViewSettingsType | null = null;
    user_on_calendar: boolean = false;

    @Mutation
    setUserList(technicians: Array<CalendarTechniciansType>) {
        const userList = technicians.map(item => ({
            name: item.full_name || 'Unknown',
            pro_title: item.pro_title,
            color: item.color,
            id: item.id,
        }));
        this.usersList = removeDuplicates(userList, 'id');
    }

    @Mutation
    setAvailabilitiesToStore(availabilities: Array<BookingEventType | ConsultEventType | ServicePointShiftListType>) {
        this.post_code_availabilities = availabilities;
    }

    @Mutation
    setUserOnCalendar(value: boolean) {
        this.user_on_calendar = value;
    }

    @Mutation
    clearCalendarState() {
        this.post_code_availabilities = [];
        this.post_code = '';
        this.usersList = [];
        this.calendar_view_data = null;
    }

    @Mutation
    addEventToStore(event: ConsultEventType) {
        this.post_code_availabilities.push(event);
    }

    @Mutation
    setCalendarViewData(calendar_view_data: CalendarViewSettingsType) {
        this.calendar_view_data = calendar_view_data;
    }

    @Action
    actionAvailabilitiesToStore(data: SetAvailabilitiesToStoreType) {
        this.context.commit('setAvailabilitiesToStore', data.availabilities);
        if (data.setUsers && data.isBookingEventType) {
            this.context.commit('setUserList', data.technicians);
        }
    }

    @Mutation
    setPostCode(post_code: string) {
        this.post_code = post_code;
    }

    @Mutation
    setCountryIso(country_iso: string) {
        this.country_iso = country_iso;
    }
}

export default CalendarStore;
